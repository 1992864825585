import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import { contextAPI } from './Context';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import Logo from './aidoc copy.png'
import { toast,Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthToken } from './setAuthToken';
function Login() {
    const [HospitialName, setHospital] = useState("");
    const [doctorName,setDoctorName]=useState('')
    let data = React.useContext(contextAPI)
    const Navigate=useNavigate()

    let handleLogin=async()=>{
      localStorage.setItem("DoctorDetials",JSON.stringify({
        doctorName,HospitialName
      }))
      Navigate("/Home")
    }

  return <>
  <div className='Login-page'>
  <div className='page-left-side'>
    <img src='7317079-removebg-preview.png' className='animation' />
    <img src={Logo} className="logo-img" alt="Logo" />
    </div>
    <div className='page-right-side'>
        <div id='Signin'>
            <p>Enter Details</p>
            <TextField sx={{ m: 1, width: '30ch' }} id="outlined-basic" label="Name" variant="outlined" onChange={(e)=>setDoctorName(e.target.value)}/>
            <TextField sx={{ m: 1, width: '30ch' }} id="outlined-basic" label="Institution/Hospital Name" variant="outlined" onChange={(e)=>setHospital(e.target.value)}/>
        <Button variant="contained" className="Login-btn" onClick={handleLogin}>Next</Button>
        </div>
    </div>
    </div>
  </>
}

export default Login