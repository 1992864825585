import React from "react";
import SideBar from "./SideBar";
import { useContext, useState, useEffect, useRef } from "react";
import { contextAPI } from './Context';
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useTheme } from '@mui/material/styles';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { CChart, CChartBar } from '@coreui/react-chartjs'
import ChatIcon from '@mui/icons-material/Chat';
import HeightIcon from '@mui/icons-material/Height';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SummarizeIcon from '@mui/icons-material/Summarize';
import male from './patient.png'
import female from './patient_1430454.png'
import PostAddIcon from '@mui/icons-material/PostAdd';
import SendIcon from '@mui/icons-material/Send';
import { setAuthToken } from './setAuthToken';
import CloseIcon from '@mui/icons-material/Close';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { io } from "socket.io-client";
import axios from 'axios'
import AgoraUIKit from 'agora-react-uikit';
import { Link } from "react-router-dom";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { getDatabase, ref, onValue, } from "firebase/database";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { v4 as uuidv4 } from "uuid";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, IconButton } from "@mui/material";
const actions = [
    { icon: <AddPhotoAlternateIcon />, name: 'image' },
    { icon: <VideoFileIcon />, name: 'video' },
    { icon: <AudioFileIcon />, name: 'Audio' },
    { icon: < DocumentScannerIcon />, name: 'Documents' },
];
function Patient() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    let [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const socket = useRef();
    let [prakriti_data, setPrakriti] = useState([])
    let [vikriti_data, setvikriti] = useState([])
    let navigate = useNavigate()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    let { id } = useParams()

    function onNavigate(id) {
        const db = getDatabase();
        const storedDetails = localStorage.getItem("DoctorDetials");

        const Details = JSON.parse(storedDetails);

        const refPath = `/users/${Details.doctorName}/${id}/casesheet`;

        const dataRef = ref(db, refPath);
        onValue(dataRef, (snapshot) => {
            if (snapshot.exists()) {
                navigate(`/UserCaseStudy/${id}`)
            } else {
                console.log("No data exists at the location, redirecting...");
                navigate(`/CaseSheet/${id}`);
            }
        }, (error) => {
            console.error("Error fetching data:", error);
        });
    }

    let getData = () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/profiles/${id}`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data2 = await snapshot.val()
                    setData(data2)
                    console.log(data2)
                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])


    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className="Patient-section">
                    <div className="patient-details">
                        {data?.gender?.toLowerCase() === ('Male').toLowerCase() ? <>
                            <img src={male} className="patient-img"></img>
                        </> : <><img src={female} className="patient-img"></img></>
                        }
                        <p id='patient-name' >{data?.firstname?.split('_')[0] + " " + data?.lastname}</p>
                        <p id='age'>{data?.age} <span id='years'>years</span></p>
                    </div>
                    <div className="patient-more-details">
                        <div className="paitent-contact-details">
                            <div>
                                <p id='patient-contact-title'>OP No</p>
                                <p id='patient-contact-content'>{data?.opno}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Phone Number</p>
                                <p id='patient-contact-content'>{data?.phone}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Email</p>
                                <p id='patient-contact-content'>{data?.email}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Occupation</p>
                                <p id='patient-contact-content'>{data?.occupation}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Consultation Date</p>
                                <p id='patient-contact-content'>{data?.screendate}</p>
                            </div>
                            <div>
                                <p id='patient-contact-title'>Admitted On</p>
                                <p id='patient-contact-content'>{data?.screendate}</p>
                            </div>
                            <div className="cstdiv" onClick={() => { onNavigate(id) }}>
                                <ListAltIcon></ListAltIcon>
                                <p>CaseSheet</p>
                                <NavigateNextIcon></NavigateNextIcon>
                            </div>

                            <div className="cstdiv" onClick={() => { navigate(`/Reports/${id}`) }}>
                                <BackupTableIcon></BackupTableIcon>
                                <p>Reports</p>
                                <NavigateNextIcon></NavigateNextIcon>
                            </div>
                            <div className="cstdiv" onClick={() => { navigate(`/Medicine/${id}`) }}>
                             <MedicationLiquidIcon></MedicationLiquidIcon>
                                <p>Add Medicine</p>
                                <NavigateNextIcon></NavigateNextIcon>
                            </div>
                            <div className="cstdiv" onClick={() => { navigate(`/FollowUpAssesment/${id}`) }}>
                                <SummarizeIcon></SummarizeIcon>
                                <p>Follow up Assesment</p>
                                <NavigateNextIcon></NavigateNextIcon>
                            </div>
                            <div className="cstdiv" onClick={() => { navigate(`/LabReports/${id}`) }}>
                                <SummarizeIcon></SummarizeIcon>
                                <p>Lab Reports</p>
                                <NavigateNextIcon></NavigateNextIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="paitent-results">
                    <h1>
                        Results
                    </h1>
                    <div className="charts">
                        <div className='chart'>
                            <CChart
                                type="bar"
                                data={{
                                    labels: ['Vata', 'Pitta', 'Kapha',],
                                    datasets: [
                                        {
                                            label: "Vata",
                                            backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                            data: [(parseInt(data?.VIKRITIVATAPULSE)), (parseInt(data?.VIKRITIPITTAPULSE)), (parseInt(data?.VIKRITIKAPHAPULSE))],
                                            formatter: (value) => `${value}%`,
                                        },
                                    ],

                                }}


                            />


                            <p>Vikriti</p>
                        </div>
                        <div className='chart'>
                            <CChart

                                type="pie"
                                data={{
                                    labels: ['Vata', 'Pitta', 'Kapha',],
                                    datasets: [
                                        {
                                            backgroundColor: ['rgb(100,181,246)', 'rgb(129,199,132)', 'rgb(229,115,115)'],
                                            data: [parseInt(data.vata), parseInt(data.pitta), parseInt(data.kappa)],
                                        },
                                    ],
                                }}
                            />
                            <p>Prakriti</p>
                        </div>
                    </div>
                </div>


                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You want to complete this Appointment
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            No
                        </Button>
                        <Button autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen={fullScreen}
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You want to confirm this Appointment
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose2}>
                            No
                        </Button>
                        <Button autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    </>

}
export default Patient