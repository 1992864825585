import React, { useState, useEffect } from 'react';
import { getDatabase, ref, update } from 'firebase/database';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';

const TableRow = ({ parameter, value, caseId, doctorName, rowname }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    useEffect(() => {
        setEditedValue(value);
    }, [value]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        setIsEditing(false);
        const db = getDatabase();
        const updateRef = ref(db, `/users/${doctorName}/${caseId}/casesheet`);
        const updates = {};
        updates[parameter] = editedValue;
        update(updateRef, updates)
            .then(() => {
                console.log('Update successful');
            })
            .catch((error) => {
                console.error('Update failed: ', error);
            });
    };

    return (
        <tr>
            <td>{rowname}</td>
            <td>
                {isEditing ? (
                    <input 
                        type="text" 
                        value={editedValue || ''} 
                        onChange={(e) => setEditedValue(e.target.value)}
                    />
                ) : (
                    <span>{value || "Not Available"}</span>
                )}
            </td>
            <td>
                {isEditing ? (
                    <IconButton onClick={handleSave} size="small">
                        <SaveIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleEdit} size="small">
                        <EditIcon />
                    </IconButton>
                )}
            </td>
        </tr>
    );
};

export default TableRow;
