import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyDUpiyCF7dYflEmlbQtzX5Fd2sbYVz-R60",
  authDomain: "ayuraisaradha.firebaseapp.com",
  databaseURL: "https://ayuraisaradha-default-rtdb.firebaseio.com",
  projectId: "ayuraisaradha",
  storageBucket: "ayuraisaradha.appspot.com",
  messagingSenderId: "166784201831",
  appId: "1:166784201831:web:1caee9f9086c47282450c2",
  measurementId: "G-VQXGYENC1D"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app); 
const  db=getDatabase(app)
export {db,storage}
