import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { getDatabase, ref, onValue, set, get, push, remove } from "firebase/database";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, TextField, FormControlLabel, Typography, Checkbox, FormGroup, FormControl, FormLabel, Select, SelectChangeEvent, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';
function FollowUpAssesment() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [Dosham, setDosham] = useState({
        Decreased: '',
        Increased: '',
    })
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [followupdate, setfollowupDate] = useState('')
    const [open, setOpen] = useState(false);

    const [MedicineData, setMedicineData] = useState({
        medtype: '',
        medicine: '',
        dose: "",
        duration: "",
        anupana: "",
        foodtaken: ""
    });

    const [medicinedata, setmedicinedata] = useState([])

    const [timings, setTimings] = useState([]);

    const [inputs, setInputs] = useState([
        { complaints: '', score: "", quantity: '' },
    ]);

    const handleInputChange = (index, inputValue, field) => {
        setInputs((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index][field] = inputValue;
            return newInputs;
        });
    };

    const handleAddField = () => {
        setInputs([...inputs, { complaints: '', score: "", duration: '' }]);
    };

    const [exam, setexam] = useState('na')
    const handleRemovecomplaints = (indexToRemove) => {

        const updated = [...inputs];
        updated.splice(indexToRemove, 1);
        setInputs(updated);
    };
    const handletimingsChange = (event) => {
        const value = event.target.name;
        let updatedTimings = [...timings];

        if (event.target.checked) {
            if (updatedTimings.length < 3) {
                updatedTimings.push(value);
            }
        } else {
            updatedTimings = updatedTimings.filter((timing) => timing !== value);
        }

        setTimings(updatedTimings);
    };

    const handleSaveMedicines = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const treename = Date.now().toString()
            const Payload = {
                assessment: inputs.map((e) => {
                    return `${e?.complaints || 'NA'}:${e?.score || 'NA'}:${e?.duration || 'NA'}`
                }).join(','),
                exam,
                treename,
                increaseddosha: Dosham.Increased,
                decreaseddosha: Dosham.Decreased,
                associatedprakriti: "",
                dominantprakriti: "",
                audiopath: "no",
                timestamp: new Date().toString(),
                type: "med"
            }
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/followup_assessment/${treename}`);
            await set(Ref, Payload)
            handleClose()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;


        setMedicineData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };



    const HandleDosham = (key, value, checked) => {
        let selectedDoshas = Dosham[key] ? Dosham[key].split(',').filter(Boolean) : [];
        if (checked) {
            if (selectedDoshas.length < 3) {
                selectedDoshas.push(value);
            } else {

                console.log('Maximum selection limit reached');
            }
        } else {
            selectedDoshas = selectedDoshas.filter(dosha => dosha !== value);
        }
        setDosham(prev => ({
            ...prev,
            [key]: selectedDoshas.join(',')
        }))
    }


    const getMedicineData = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/followup_assessment`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data2 = await snapshot.val()
                    setmedicinedata(Object.entries(data2))
                    console.log(data2)
                }
                catch (err) {
                    console.log(err)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const deleteMedicine = (key) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/followup_assessment/${key}`);
            remove(Ref)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getMedicineData()
    }, [])

    return <>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Add Assessment"}
            </DialogTitle>
            <DialogContent>
                <div className='medicineinputtypes' style={{ gridColumn: "1" }}>
                    <label style={{ padding: "5px" }} id='examination'>Date of follow up
                        <input
                            style={{ padding: "5px", fontWeight: 500 }}
                            type='date'
                            name="DateOfConsultation"
                            onChange={(e) => { setfollowupDate(new Date(e?.target?.value).toString()) }}
                        />
                    </label>
                    <div className='Medication'>
                        <div className='diagnois'>
                            <p>Complaints</p>
                            <div>
                                <>{inputs.map((input, index) => (
                                    <div className='medicine' key={index}>

                                        <TextField
                                            className='medicine-input'
                                            label="Enter Complaints"
                                            id="standard-size-small"
                                            value={input.complaints}
                                            onChange={(event) => handleInputChange(index, event.target.value, "complaints")}
                                            variant="standard"
                                        />
                                        <TextField
                                            label="Score"
                                            id="standard-size-small"
                                            className='medicine-input'
                                            value={input.score}
                                            onChange={(event) => handleInputChange(index, event.target.value, "score")}
                                            variant="standard"
                                        />
                                        <TextField
                                            label="Duration"
                                            className='medicine-input'
                                            id="standard-size-small"
                                            value={input.duration}
                                            onChange={(event) => handleInputChange(index, event.target.value, "duration")}
                                            variant="standard"
                                        />
                                        <IconButton onClick={() => handleRemovecomplaints(index)} style={index > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
                                            <CancelIcon style={{ color: "red" }} />
                                        </IconButton>
                                    </div>))}
                                    <div className='add-more' onClick={handleAddField}>
                                        Add Complaints <AddCircleOutlineIcon />
                                    </div>
                                </>
                            </div>


                        </div>





                    </div>

                    <h1 id='examination'>Examination</h1>

                    <textarea style={{ width: "auto" }} onChange={(e) => {
                        setexam(e.target.value)
                    }}></textarea>
                    <div className='diagnois'>
                        <p>Dosham</p>
                        <div className='disorders-card'>
                            <div style={{ width: "25rem" }}>
                                <p>Decreased</p>
                                <div >
                                    <input
                                        type="checkbox"
                                        id="Associated1"
                                        name="Associated"
                                        value="Vata"
                                        onChange={(e) => {
                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Vata')}
                                    />
                                    <label htmlFor="Associated1">Vata</label><br />

                                    <input
                                        type="checkbox"
                                        id="Associated2"
                                        name="Associated"
                                        value="Pitta"
                                        onChange={(e) => {
                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Pitta')}
                                    />
                                    <label htmlFor="Associated2">Pitta</label><br />

                                    <input
                                        type="checkbox"
                                        id="Associated3"
                                        name="Associated"
                                        value="Kapha"
                                        onChange={(e) => {
                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Kapha')}
                                    />
                                    <label htmlFor="Associated3">Kapha</label><br />
                                </div>
                            </div>

                        </div>
                        <div className='disorders-card'>
                            <div style={{ width: "25rem" }}>
                                <p>Increased</p>
                                <div >
                                    <input
                                        type="checkbox"
                                        id="Associated1"
                                        name="Associated"
                                        value="Vata"
                                        onChange={(e) => {
                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Vata')}
                                    />
                                    <label htmlFor="Associated1">Vata</label><br />

                                    <input
                                        type="checkbox"
                                        id="Associated2"
                                        name="Associated"
                                        value="Pitta"
                                        onChange={(e) => {
                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Pitta')}
                                    />
                                    <label htmlFor="Associated2">Pitta</label><br />

                                    <input
                                        type="checkbox"
                                        id="Associated3"
                                        name="Associated"
                                        value="Kapha"
                                        onChange={(e) => {
                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                        }}
                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Kapha')}
                                    />
                                    <label htmlFor="Associated3">Kapha</label><br />
                                </div>
                            </div>



                        </div>

                    </div>

                </div>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    cancel
                </Button>
                <Button autoFocus onClick={handleSaveMedicines}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Followup Assessment Report</p>
            <div className='listofreports'>
                {
                    medicinedata.map(([key, e]) => {
                        return <div className='reportcard'>
                            <div>
                                <p style={{ fontSize: "12px", color: "green" }}>{e.timestamp}</p>
                                <div>
                                    <p id='info-t'>Complaints</p>
                                    <div>

                                        <table id='table1' >
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Score</th>
                                                    <th>Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {e.assessment.split(',').map((e) => {
                                                    return <tr>
                                                        <td>{e.split(":")[0]}</td>
                                                        <td>{e.split(":")[1]}</td>
                                                        <td>{e.split(":")[2]}</td>
                                                    </tr>
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Examination  </p>
                                    <p style={{ fontWeight: 400 }}>{e?.exam}</p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Increased Dosha  </p>
                                    <p style={{ fontWeight: 400 }}>{e?.increaseddosha}</p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Decreased Dosha  </p>
                                    <p style={{ fontWeight: 400 }}>{e?.decreaseddosha}</p>
                                </Typography>
                            </div>
                            <IconButton style={{ alignContent: "flex-end" }}>
                                <DeleteIcon onClick={() => {
                                    deleteMedicine(key)
                                }}></DeleteIcon>
                            </IconButton>
                        </div>
                    })
                }
            </div>
            <div className='addrprtbtn'><Button style={{ color: "white", padding: "8px 3rem 8px 3rem", width: "100%" }} onClick={() => {
                handleClickOpen()
            }}>Take Assessment</Button></div>
        </div>

    </>
}

export default FollowUpAssesment