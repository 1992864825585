import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { getDatabase, ref, onValue, set, get, push, remove, update } from "firebase/database";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Button, TextField, Grid, FormControlLabel, Typography, Checkbox, FormGroup, FormControl, FormLabel, Select, SelectChangeEvent, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';
import { Details } from '@mui/icons-material';
function LabReports() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [Dosham, setDosham] = useState({
        Decreased: "",
        Increased: "",
    })
    const theme = useTheme();
    const [editableStates, setEditableStates] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [followupdate, setfollowupDate] = useState("")
    const [open, setOpen] = useState(false);

    const [formData, setFormData] = useState({
        Hb: "", TCDC: "", PlateletCount: "", ESR: "", CompleteHaemogram: "", PSmear: "",
        FastingBloodSugar: "", PPBloodSugar: "", PrickBloodSugar: "", RandomBloodSugar: "", HbA1C: "",
        BUN: "", Urea: "", Creatinine: "", UricAcid: "", Calcium: "", Cholesterol: "", LipidProfile: "",
        HBsAg: "", HCV: "", Widal: "", SGOT: "", SGPT: "", AlkPhosphatase: "", GGT: "",
        TotalProtein: "", Albumin: "", Globulin: "", AGRatio: "", LiverFunctionTest: "",
        AntiLKM: "", AntiSMA: "", AntiMCA: "", AFP: "", Electrolytes: "", ASO: "", CRP: "", CPK: "",
        Anticcp: "", Amylase: "", Lipase: "", Ferritin: "", LDH: "", IonisedCalcium: "", TBFerron: "",
        VitaminB12: "", VitaminD3: "", IGE: "", FolicAcid: "", IronDeficiencyProfile: "",
        ANA: "", ANAProfile: "", DSDNA: "", HLAB27: "", PSA: "", TFT: "", FTFT: "", AntiTPO: "",
        Testosterone: "", Bilesalts: "", Bilepigments: "", SpotMicroAlbumin: "", UrineRoutine: ""
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form Data Submitted:", formData);
    };

    const [MedicineData, setMedicineData] = useState({
        medtype: "",
        medicine: "",
        dose: "",
        duration: "",
        anupana: "",
        foodtaken: ""
    });

    const [medicinedata, setmedicinedata] = useState([])

    const [timings, setTimings] = useState([]);







    const [exam, setexam] = useState("")

    const handletimingsChange = (event) => {
        const value = event.target.name;
        let updatedTimings = [...timings];

        if (event.target.checked) {
            if (updatedTimings.length < 3) {
                updatedTimings.push(value);
            }
        } else {
            updatedTimings = updatedTimings.filter((timing) => timing !== value);
        }

        setTimings(updatedTimings);
    };

    const handleSaveMedicines = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const treename = Date.now().toString()
            const Payload = {
                BloodTest: formData,
                additionalTests: additionalTests,
                timestamp: new Date().toString(),
                treename:treename
            }
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/lab_reports/${treename}`);
            await set(Ref, Payload)
            handleClose()
        }
        catch (err) {
            console.log(err)
        }
    }


    const handleSave = async(treename,index) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/lab_reports/${treename}`);
            const Payload = medicinedata[index][1];
            await update(Ref, Payload);
            console.log('Update successful');
            toggleEdit(index);
        } catch (error) {
            console.error('Update failed:', error);
        }
        
    };


    const handleChange = (e) => {
        const { name, value } = e.target;


        setMedicineData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };



    const HandleDosham = (key, value, checked) => {
        let selectedDoshas = Dosham[key] ? Dosham[key].split(',').filter(Boolean) : [];
        if (checked) {
            if (selectedDoshas.length < 3) {
                selectedDoshas.push(value);
            } else {

                console.log('Maximum selection limit reached');
            }
        } else {
            selectedDoshas = selectedDoshas.filter(dosha => dosha !== value);
        }
        setDosham(prev => ({
            ...prev,
            [key]: selectedDoshas.join(',')
        }))
    }


    const getMedicineData = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/lab_reports`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data2 = await snapshot.val()
                    setmedicinedata(Object.entries(data2))
                    setEditableStates(Array(data2?.length).fill(false))
                    console.log(data2)
                }
                catch (err) {
                    console.log(err)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const deleteMedicine = (key) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/lab_reports/${key}`);
            remove(Ref)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getMedicineData()
    }, [])




    const [additionalTests, setAdditionalTests] = useState([]);
    const handleTestNameChange = (index, value) => {
        const updatedTests = additionalTests.map((test, idx) => {
            if (idx === index) {
                return { ...test, testName: value };
            }
            return test;
        });
        setAdditionalTests(updatedTests);
    };

    const handleFieldChange = (testIndex, fieldIndex, key, value) => {
        const updatedTests = additionalTests.map((test, idx) => {
            if (idx === testIndex) {
                const updatedData = test.data.map((field, idx) => {
                    if (idx === fieldIndex) {
                        return { ...field, [key]: value };
                    }
                    return field;
                });
                return { ...test, data: updatedData };
            }
            return test;
        });
        setAdditionalTests(updatedTests);
    };

    const handleAddTest = () => {
        setAdditionalTests([...additionalTests, { testName: "", data: [{ key: "", value: "" }] }]);
    };

    const handleAddField = (testIndex) => {
        const updatedTests = additionalTests.map((test, idx) => {
            if (idx === testIndex) {
                return { ...test, data: [...test.data, { key: "", value: "" }] };
            }
            return test;
        });
        setAdditionalTests(updatedTests);
    };

    const handleRemoveTest = (index) => {
        const filteredTests = additionalTests.filter((_, idx) => idx !== index);
        setAdditionalTests(filteredTests);
    };

    const handleRemoveField = (testIndex, fieldIndex) => {
        const updatedTests = additionalTests.map((test, idx) => {
            if (idx === testIndex) {
                const filteredData = test.data.filter((_, idx) => idx !== fieldIndex);
                return { ...test, data: filteredData };
            }
            return test;
        });

        setAdditionalTests(updatedTests);
    };






    const toggleEdit = (index) => {
        const newEditableStates = [...editableStates];
        newEditableStates[index] = !newEditableStates[index];
        setEditableStates(newEditableStates);
    };




    const handleInputChange2 = (key, value, index) => {
        const newData = [...medicinedata];
        newData[index][1].BloodTest[key] = value; // Update based on correct path to data
        setMedicineData(newData);
    };

    const handleAdditionalTestChange = (testIndex, fieldIndex, value, mainIndex) => {
        const newData = [...medicinedata];
        newData[mainIndex][1].additionalTests[testIndex].data[fieldIndex].value = value;
        setMedicineData(newData);
    };

    return <>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="testName">
                {"Add Lab Reports"}
            </DialogTitle>
            <DialogContent>
                <div className='medicineinputtypes' style={{ gridColumn: "1" }}>
                    <label style={{ padding: "5px" }} id='examination'>Date
                        <input
                            style={{ padding: "5px", fontWeight: 500 }}
                            type='date'
                            name="DateOfConsultation"
                            onChange={(e) => { setfollowupDate(new Date(e?.target?.value).toString()) }}
                        />
                    </label>
                    <div className='Medication'>
                        <div className='diagnois'>
                            <p>Blood Test</p>
                        </div>
                        {/* <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {Object.keys(formData).map((key, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <TextField
                                            fullWidth
                                            label={key.replace(/([A-Z])/g, ' $1').trim().replace(/_/g, ' ')}
                                            name={key}
                                            id="standard-size-small"
                                            variant="standard"
                                            value={formData[key]}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </form> */}
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {Object.keys(formData)?.map((key, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <TextField
                                            fullWidth
                                            label={key.replace(/([A-Z])/g, ' $1').trim().replace(/_/g, ' ')}
                                            name={key}
                                            className='medicine-input'
                                            variant="outlined"
                                            id="outlined-basic"
                                            value={formData[key]}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <div className='diagnois'>
                                        <p>Additional Tests</p>
                                    </div>
                                    {additionalTests.map((test, testIndex) => (
                                        <div key={testIndex}>
                                            <TextField
                                                fullWidth
                                                label="Test Name"
                                                variant="outlined"
                                                id="outlined-basic"
                                                value={test.testName}
                                                onChange={(e) => handleTestNameChange(testIndex, e.target.value)}
                                                style={{ margin: '10px 0' }}
                                            />
                                            {test.data.map((field, fieldIndex) => (
                                                <Grid container spacing={2} key={fieldIndex} marginTop={1}>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            label="Field Name"
                                                            variant="outlined"
                                                            id="outlined-basic"
                                                            value={field.key}
                                                            onChange={(e) => handleFieldChange(testIndex, fieldIndex, 'key', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} >
                                                        <TextField
                                                            fullWidth
                                                            label="Field Value"
                                                            variant="outlined"
                                                            value={field.value}
                                                            id="outlined-basic"
                                                            onChange={(e) => handleFieldChange(testIndex, fieldIndex, 'value', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton onClick={() => handleRemoveField(testIndex, fieldIndex)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button startIcon={<AddIcon />} onClick={() => handleAddField(testIndex)} variant="contained" color="secondary" marginTop={2}>
                                                Add Field
                                            </Button>
                                            <IconButton onClick={() => handleRemoveTest(testIndex)} style={{ marginTop: 8 }}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </div>
                                    ))}
                                    <Button startIcon={<AddIcon />} onClick={handleAddTest} variant="contained" color="primary">
                                        Add Test
                                    </Button>
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }}>
                                Submit
                            </Button>
                        </form>

                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    cancel
                </Button>
                <Button autoFocus onClick={handleSaveMedicines}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Lab Reports</p>
            <div className='listofreports'>
                {medicinedata.map(([key, data], index) => (
                    <div key={key} style={{ marginBottom: 20 }}>
                        <TableContainer component={Paper} elevation={5}>
                            <Table className="fullWidthTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="h6" id="testname">Test Name</Typography></TableCell>
                                        <TableCell><Typography variant="h6" id="testname">Value</Typography></TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => {
                                                if (editableStates[index]) {
                                                    handleSave(key,index);
                                                } else {
                                                    toggleEdit(index);
                                                }
                                            }
                                            }
                                            >
                                                {editableStates[index] ? <SaveIcon /> : <EditIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(data.BloodTest)
                                    // .filter(([key, value]) => value != "")
                                    .map(([key, value]) => (
                                        <TableRow key={key} id="tbrow">
                                            <TableCell>{key} (Blood Test)</TableCell>
                                            <TableCell>
                                                {editableStates[index] ? (
                                                    <TextField
                                                        value={value}
                                                        onChange={(e) => handleInputChange2(key, e.target.value, index)}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                ) : (
                                                 value !='' ? value: "No data"
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {data.additionalTests &&
                                        data?.additionalTests?.map((test, testIndex) => (
                                            test?.data?.map((field, fieldIndex) => (
                                                <TableRow key={`${test.testName}-${fieldIndex}`}>
                                                    <TableCell>{field.key} ({test.testName})</TableCell>
                                                    <TableCell>
                                                        {editableStates[index] ? (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => handleAdditionalTestChange(testIndex, fieldIndex, e.target.value, index)}
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        ) : (
                                                            field.value
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ))}
            </div>

            {/* <div className='listofreports'>
                {
                    medicinedata.map(([index, data]) => {
                        return <div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Test Name</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(data.BloodTest).filter(([key,value])=>{
                                        if(value!=""){
                                            return key
                                        }
                                    }).map(([key, value]) => (
                                        <TableRow key={key}>
                                            <TableCell>{key} (Blood Test)
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={value}
                                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {data.additionalTests.map((test, index) => (
                                        test.data.map((field, fieldIndex) => (
                                            <TableRow key={`${test.testName}-${fieldIndex}`}>
                                                <TableCell>{field.key} ({test.testName})</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => handleAdditionalTestChange(index, 'value', e.target.value)}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button onClick={handleSave} variant="contained" color="primary" style={{ margin: '20px' }}>
                            Save Data
                        </Button>
                    </div>
                    })
                }
            </div> */}



            <div className='addrprtbtn'><Button style={{ color: "white", padding: "8px 3rem 8px 3rem", width: "100%" }} onClick={() => {
                handleClickOpen()
            }}>Add</Button></div>
        </div>

    </>
}

export default LabReports