// UserCaseStudy.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import TableRow from './TableRow'; // Import the TableRow component
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function UserCaseStudy() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [CaseStudyData, setCaseStudyData] = useState([]);

    useEffect(() => {
        const db = getDatabase();
        const Details = JSON.parse(localStorage.getItem("DoctorDetials"));
        const Ref = ref(db, `/users/${Details.doctorName}/${id}/casesheet`);
        onValue(Ref, (snapshot) => {
            const data = snapshot.val();
            setCaseStudyData(data);

        });
    }, [id]);

    if (!CaseStudyData) {
        return (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    // const rows = (
    //     <>
    //         <TableRow
    //             key="timestamp"
    //             parameter="timestamp"
    //             rowname="Date"
    //             value={CaseStudyData?.timestamp}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="doctorname"
    //             parameter="doctorname"
    //             rowname="Doctor"
    //             value={CaseStudyData?.doctorname}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="patientid"
    //             parameter="patientid"
    //             rowname="Patient Name"
    //             value={CaseStudyData?.patientid}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="PULSERATECASE"
    //             parameter="PULSERATECASE"
    //             rowname="Heart Rate"
    //             value={CaseStudyData?.PULSERATECASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="PRESENTING_COMPLAINTS"
    //             parameter="PRESENTING_COMPLAINTS"
    //             rowname="Presenting Complaints"
    //             value={CaseStudyData?.PRESENTING_COMPLAINTS}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HYPERTENSION_CASE"
    //             parameter="HYPERTENSION_CASE"
    //             rowname="Hypertension"
    //             value={CaseStudyData?.hypertension}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="IHD"
    //             parameter="IHD"
    //             rowname="IHD/CAD"
    //             value={CaseStudyData?.IHD}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="ASTHMA_CASE"
    //             parameter="ASTHMA_CASE"
    //             rowname="Bronchial asthma"
    //             value={CaseStudyData?.ASTHMA_CASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="TB"
    //             parameter="TB"
    //             rowname="T.B"
    //             value={CaseStudyData?.TB}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="ALLERGY"
    //             parameter="ALLERGY"
    //             rowname="Allergy"
    //             value={CaseStudyData?.ALLERGY}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="APD"
    //             parameter="APD"
    //             rowname="APD"
    //             value={CaseStudyData?.APD}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="JAUNDICE"
    //             parameter="JAUNDICE"
    //             rowname="Jaundice"
    //             value={CaseStudyData?.JAUNDICE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="EAOA"
    //             parameter="EAOA"
    //             rowname="RA/OA"
    //             value={CaseStudyData?.EAOA}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="SKINDISEASE"
    //             parameter="SKINDISEASE"
    //             rowname="Skin disease"
    //             value={CaseStudyData?.EAOA}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="THYROID"
    //             parameter="THYROID"
    //             rowname="Thyroid"
    //             value={CaseStudyData?.THYROID}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="ANAEMIA"
    //             parameter="ANAEMIA"
    //             rowname="Anaemia"
    //             value={CaseStudyData?.ANAEMIA}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="PILES"
    //             parameter="PILES"
    //             rowname="Piles"
    //             value={CaseStudyData?.PILES}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HEADACHE"
    //             parameter="HEADACHE"
    //             rowname="Headache / vomiting"
    //             value={CaseStudyData?.HEADACHE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="SEIZURES"
    //             parameter="SEIZURES"
    //             rowname="Seizures"
    //             value={CaseStudyData?.SEIZURES}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="MENSTURATION"
    //             parameter="MENSTURATION"
    //             rowname="MENSTURATION"
    //             value={CaseStudyData?.MENSTURATION}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="INJURIES"
    //             parameter="INJURIES"
    //             rowname="Injuries"
    //             value={CaseStudyData?.INJURIES}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="SURGERIES"
    //             parameter="SURGERIES"
    //             rowname="Surgeries"
    //             value={CaseStudyData?.SURGERIES}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HYSTERECTOMY"
    //             parameter="HYSTERECTOMY"
    //             rowname="Hysterectomy"
    //             value={CaseStudyData?.HYSTERECTOMY}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="CHILDREN"
    //             parameter="CHILDREN"
    //             rowname="CHILDREN"
    //             value={CaseStudyData?.CHILDREN}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="PAST_HISTORY"
    //             parameter="PAST_HISTORY"
    //             rowname="Family Past History"
    //             value={CaseStudyData?.PAST_HISTORY}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HABITS_SMOKING"
    //             parameter="HABITS_SMOKING"
    //             rowname="Smoking ?"
    //             value={CaseStudyData?.HABITS_SMOKING}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HABITS_ALCOHOL"
    //             parameter="HABITS_ALCOHOL"
    //             rowname="Alcohol ?"
    //             value={CaseStudyData?.HABITS_ALCOHOL}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HABITS_TOBACCO"
    //             parameter="HABITS_TOBACCO"
    //             rowname="Tobacco ?"
    //             value={CaseStudyData?.HABITS_TOBACCO}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="DRUGS"
    //             parameter="DRUGS"
    //             rowname="Current Drugs"
    //             value={CaseStudyData?.DRUGS}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="CLINICALEXAMINATION"
    //             parameter="CLINICALEXAMINATION"
    //             rowname="Clinical Examination"
    //             value={CaseStudyData?.CLINICALEXAMINATION}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="HEIGHTCASE"
    //             parameter="HEIGHTCASE"
    //             rowname="Height"
    //             value={CaseStudyData?.HEIGHTCASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="WEIGHTCASE"
    //             parameter="WEIGHTCASE"
    //             rowname="Weight"
    //             value={CaseStudyData?.HEIGHTCASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="PULSERATECASE"
    //             parameter="PULSERATECASE"
    //             rowname="Pulse rate"
    //             value={CaseStudyData?.PULSERATECASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="BPCASE"
    //             parameter="BPCASE"
    //             rowname="Blood Pressure Rate"
    //             value={CaseStudyData?.BPCASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="TEMPERATURECASE"
    //             parameter="TEMPERATURECASE"
    //             rowname="Body Temperature"
    //             value={CaseStudyData?.TEMPERATURECASE}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />

    //         <TableRow
    //             key="DOMINANT"
    //             parameter="DOMINANT"
    //             rowname="Prakriti Dominant"
    //             value={CaseStudyData?.DOMINANT}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="ASSOCIATED"
    //             parameter="ASSOCIATED"
    //             rowname="Prakriti Associated"
    //             value={CaseStudyData?.ASSOCIATED}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="DECREASED"
    //             parameter="DECREASED"
    //             rowname="Prakriti Decreased"
    //             value={CaseStudyData?.DECREASED}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="INCREASED"
    //             parameter="INCREASED"
    //             rowname="Prakriti Increased"
    //             value={CaseStudyData?.INCREASED}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="NIDRA"
    //             parameter="NIDRA"
    //             rowname="Nidra / Duration"
    //             value={CaseStudyData?.NIDRA}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="MANAS"
    //             parameter="MANAS"
    //             rowname="Manas"
    //             value={CaseStudyData?.MANAS}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="AGNI"
    //             parameter="AGNI"
    //             rowname="Agni"
    //             value={CaseStudyData?.AGNI}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="KOSHTAM"
    //             parameter="KOSHTAM"
    //             rowname="Koshtam"
    //             value={CaseStudyData?.KOSHTAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="KOSHTAM"
    //             parameter="KOSHTAM"
    //             rowname="Koshtam"
    //             value={CaseStudyData?.KOSHTAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="MALAM"
    //             parameter="MALAM"
    //             rowname="Malam"
    //             value={CaseStudyData?.MALAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="MUTRAM"
    //             parameter="MUTRAM"
    //             rowname="MUTRAM"
    //             value={CaseStudyData?.MUTRAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //         <TableRow
    //             key="VYADHIAVASTHA"
    //             parameter="VYADHIAVASTHA"
    //             rowname="Vyadhiavastha"
    //             value={CaseStudyData?.VYADHIAVASTHA}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //             <TableRow
    //             key="VYAYAMAM"
    //             parameter="VYAYAMAM"
    //             rowname="Vyayamam"
    //             value={CaseStudyData?.VYAYAMAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //             <TableRow
    //             key="MUTRAM"
    //             parameter="MUTRAM"
    //             rowname="MUTRAM"
    //             value={CaseStudyData?.MUTRAM}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //             <TableRow
    //             key="AYURVEDADIAGNOSIS"
    //             parameter="AYURVEDADIAGNOSIS"
    //             rowname="Ayurveda Diagnosis"
    //             value={CaseStudyData?.AYURVEDADIAGNOSIS}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />
    //             <TableRow
    //             key="MODERNDIAGNOSIS"
    //             parameter="MODERNDIAGNOSIS"
    //             rowname="Modern Diagnosis"
    //             value={CaseStudyData?.MODERNDIAGNOSIS}
    //             caseId={id}
    //             doctorName={JSON.parse(localStorage.getItem("DoctorDetials")).doctorName}
    //         />

    //     </>





    // );
    const fieldMappings = {
        timestamp: "Date",
        doctorname: "Doctor",
        patientid: "Patient Name",
        PULSERATECASE: "Heart Rate",
        PRESENTING_COMPLAINTS: "Presenting Complaints",
        FBS:"FBS",
        PPBS:"PPBS",
        HBAC:"HBA1C",
        hypertension: "Hypertension",
        IHD: "IHD/CAD",
        ASTHMA_CASE: "Bronchial asthma",
        TB: "T.B",
        ALLERGY: "Allergy",
        APD: "APD",
        JAUNDICE: "Jaundice",
        EAOA: "RA/OA",
        SKINDISEASE: "Skin disease",
        THYROID: "Thyroid",
        ANAEMIA: "Anaemia",
        PILES: "Piles",
        HEADACHE: "Headache / vomiting",
        SEIZURES: "Seizures",
        MENSTURATION: "MENSTURATION",
        INJURIES: "Injuries",
        SURGERIES: "Surgeries",
        HYSTERECTOMY: "Hysterectomy",
        CHILDREN: "CHILDREN",
        PAST_HISTORY: "Family Past History",
        HABITS_SMOKING: "Smoking ?",
        HABITS_ALCOHOL: "Alcohol ?",
        HABITS_TOBACCO: "Tobacco ?",
        DRUGS: "Current Drugs",
        CLINICALEXAMINATION: "Clinical Examination",
        HEIGHTCASE: "Height",
        WEIGHTCASE: "Weight",
        BPCASE: "Blood Pressure Rate",
        TEMPERATURECASE: "Body Temperature",
        DOMINANT: "Prakriti Dominant",
        ASSOCIATED: "Prakriti Associated",
        DECREASED: "Dosha Decreased",
        INCREASED: "Dosha Increased",
        NIDRA: "Nidra / Duration",
        MANAS: "Manas",
        AGNI: "Agni",
        KOSHTAM: "Koshtam",
        MALAM: "Malam",
        MUTRAM: "MUTRAM",
        VYADHIAVASTHA: "Vyadhiavastha",
        VYAYAMAM: "Vyayamam",
        AYURVEDADIAGNOSIS: "Ayurveda Diagnosis",
        MODERNDIAGNOSIS: "Modern Diagnosis"
    };

    const generateTableRows = (data) => {
        return Object.keys(fieldMappings).map(key => ({
            key: key,
            parameter:key,
            rowname: fieldMappings[key],
            value: data[key]
        }));
    };
    const tableRows = generateTableRows(CaseStudyData);
    const doctorDetails = JSON.parse(localStorage.getItem("DoctorDetials"))?.doctorName;
    return (
        <>
            <div className='P-page'>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <div className="case-sheet">
                <h1 className="case-sheet-title">Case Sheet</h1>
                <div className="case-sheet-table-container">
                    <table className="case-sheet-table">
                        <thead>
                            {/* <tr>
                                <th>Parameter</th>
                                <th>Value/Description</th>
                            </tr> */}
                        </thead>
                        <tbody>
                            {tableRows.map(row => (
                                <TableRow
                                    key={row.key}
                                    parameter={row.parameter}
                                    rowname={row.rowname}
                                    value={row.value}  // Handle undefined or null values
                                    caseId={id}
                                    doctorName={doctorDetails}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default UserCaseStudy;
