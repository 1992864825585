import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDatabase, ref as databaseRef, onValue } from "firebase/database";

function ViewReports() {
    const { id, Reportid } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState(null);

    const getReports = () => {
        const Details = JSON.parse(localStorage.getItem("DoctorDetials"));
        if (!Details) {
            console.error("No doctor details found in localStorage");
            return;
        }

        const db = getDatabase();
        const reportRef = databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport/${Reportid}`);
        onValue(reportRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setReports(data);
                setLoading(false);
            } else {
                console.error("Snapshot data is null or undefined");
            }
        }, (error) => {
            console.error("Firebase read failed:", error);
        });
    };

    useEffect(() => {
        getReports();
    }, [id, Reportid]);

    if (loading) {
        return <div>Loading reports...</div>;
    }
    const renderMedia = () => {
        if (reports.images) {
            return Object.values(reports.images).map((image, index) => (
                <div className='Viewreportcard' key={index}>
                    <img src={image.data} alt="Report Image" />
                </div>
            ));
        } else if (reports.PDF) {

            const pdfArray = Object.values(reports.PDF);
            return pdfArray.map((pdf, index) => (
                <iframe
                    key={index}
                    src={`${pdf.data}#toolbar=0`}
                    width="900"
                    height="500"
                    title="PDF Document"
                    style={{ margin: 'auto' }}
                ></iframe>
            ));
        } else {
            return <div>No images or PDFs available.</div>;
        }
    };

    return (
        <div className='P-page'>
            <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
            </IconButton>
            <div className='Reports-Sections'>
                <div className='viewlistofReports'>
                    {renderMedia()}
                </div>
            </div>
        </div>
    );
}

export default ViewReports;
