import React from 'react'
import { useState, useRef, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogContentText from '@mui/material/DialogContentText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getFirestore } from "firebase/firestore";
import { set, ref as databaseRef, push, onValue, remove } from "firebase/database";
import { getStorage, uploadBytes, getDownloadURL, ref as storageRef } from "firebase/storage";
import { getDatabase } from "firebase/database";
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { Buffer } from "buffer";
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Patient from './Patient';
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
function Reports() {
    const navigate = useNavigate()
    const { id, doc_id } = useParams()
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [listReports, setlistReports] = useState([])
    const [Allreports, setReports] = useState([])
    const [reporttype, setReportype] = useState('')
    const [count, setcount] = useState(0)
    const [selectedOption, setSelectedOption] = useState('')
    let doctorid = sessionStorage.getItem('Doctorid')
    const data = JSON.parse(localStorage.getItem('Userdetails'))
    const fileInput = useRef();
    const [filetype, setfiletype] = useState('')

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const options = ['MRI/CI Report', 'X-Ray', 'USG Report', 'ECG Report', 'Others']

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const base64String = fileReader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                resolve(base64String)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }
    const handleUpload = async () => {
        try {
            if (!Allreports.length) {
                console.error('No files selected');
                return;
            }

            const firestore = getFirestore();
            const storage = getStorage();
            const db = getDatabase();

            const treename = Date.now().toString();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"));
            for (const file of Allreports) {
                const fileRef = storageRef(storage, `files/${file.fileName}`);
                await uploadBytes(fileRef, file.file);
                const downloadURL = await getDownloadURL(fileRef);
                await set(databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport/${treename}`), {
                    person: "Doctor",
                    report: reporttype,
                    timestamp: new Date().toString(),
                    treename
                });

                if (filetype === 'images') {
                    await push(databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport/${treename}/images`), { data: downloadURL });
                } else {
                    await push(databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport/${treename}/PDF`), { data: downloadURL });
                }
            }

            console.log('Files uploaded and saved to Firestore');
        } catch (error) {
            console.log('Error uploading files:', error);
        } finally {
            setReports([])
            setcount(count + 1)
            setReportype('')
            setfiletype('')
            handleClose2()
        }
    };



    let handleFileUpload = async (e) => {
        e.preventDefault();
        let reportsArray = fileInput.current.files;
        for (let i = 0; i < reportsArray.length; i++) {
            let file = reportsArray[i]
            let filedata = file

            setReports((prev) => [...prev, {
                file: filedata, fileName: file?.name
            }])
        }


    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
        setOpen(false)
    };

    const handleClose2 = () => {
        setOpen2(false);
        setOpen(false)
    };

    const handleInputChange = useMemo(() => {
        return (event) => {
            event.preventDefault()
            setSelectedOption(event.target.value)
        };
    }, [reporttype]);

    const handleAutocompleteChange = (event, newValue) => {
        event.preventDefault()
        setReportype(newValue);
        setSelectedOption(newValue)
    };

    const getReports = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data2 = await snapshot.val()
                    setlistReports(data2)
                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }



    const deleteReports = (key) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = databaseRef(db, `/users/${Details.doctorName}/${id}/storedreport/${key}`);
            remove(Ref)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getReports()
    }, [count])

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Select file type"}
            </DialogTitle>
            <DialogContent>
                <p id='filetypes' onClick={() => {
                    setfiletype('images')
                    handleClickOpen2()
                }}>
                    <InsertPhotoIcon style={{ color: "grey" }}
                    />  Images</p>
                <Divider />

                <p id='filetypes' onClick={() => {
                    setfiletype('PDF')
                    handleClickOpen2()
                }}>

                    <PictureAsPdfIcon style={{ color: "grey" }} />
                    PDF

                </p>

            </DialogContent>
        </Dialog>

        <Dialog
            open={open2}
            keepMounted
            onClose={handleClose2}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id='DialogTitle'>{"Upload Report"}</DialogTitle>
            <DialogContent className='uploadreport'>

                <div className='Upload_report'>
                    <Button variant="contained" component="label">
                        {filetype === 'images' ? 'Select Multiple Images' : "Select Pdf file"}
                        {filetype === 'images' ? <input hidden type='file' ref={fileInput} multiple onChange={(e) => handleFileUpload(e)} /> :
                            <input hidden type='file' ref={fileInput} accept='.pdf' onChange={(e) => handleFileUpload(e)} />}
                    </Button>
                </div>

                <div >
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        onChange={handleAutocompleteChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} value={params} label="Select Report Type" onInput={handleInputChange} />}
                    />
                    {
                        selectedOption === 'Others' && <TextField label="Enter Report name" id="outlined-basic" variant="outlined" onChange={(e) => { setReportype(e.target.value) }} sx={{ width: 300, marginTop: '1rem' }}></TextField>
                    }
                </div>
                <p id='impinfo'>Please select file size lesser than 2mb</p>

            </DialogContent>
            <DialogActions>
                <Button style={{ backgroundColor: "#007BFF", color: "white" }} onClick={handleUpload}>Next</Button>
            </DialogActions>
        </Dialog>

        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Stored Reports</p>
            <div className='listofreports'>
                {listReports && Object.entries(listReports).map(([key, e]) => {
                    return <div className='reportcard' onClick={() => {
                        navigate(`/ViewReports/${id}/${e?.treename}`)
                    }}>
                        <div>
                            <p className='mb-0'>{e?.timestamp}</p>
                            <p className='mb-0'>{e?.report}</p>
                            <p className='mb-0'>{e?.person === id ? "Uploaded by Patient" : "Uploaded by Doctor"}</p>
                        </div>
                        <IconButton onClick={(event) => {
                            event.stopPropagation()
                            deleteReports(key)
                        }}>
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </div>
                })}
            </div>
            <div className='addrprtbtn'><Button style={{ color: "white", padding: "8px 3rem 8px 3rem", width: "100%" }} onClick={handleClickOpen}>ADD REport</Button></div>
        </div>

    </>
}

export default Reports