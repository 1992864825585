import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { getDatabase, ref, onValue, set, get, push, remove } from "firebase/database";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Autocomplete, TextField, FormControlLabel, Typography, Checkbox, FormGroup, FormControl, FormLabel, Select, SelectChangeEvent, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';

function Medicine() {
    const navigate = useNavigate()
    const { id } = useParams()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = useState(false);

    const [MedicineData, setMedicineData] = useState({
        medtype: '',
        medicine: '',
        dose: "",
        duration: "",
        anupana: "",
        foodtaken: ""
    });

    const [medicinedata, setmedicinedata] = useState([])

    const [timings, setTimings] = useState([]);

    const handletimingsChange = (event) => {
        const value = event.target.name;
        let updatedTimings = [...timings];

        if (event.target.checked) {
            if (updatedTimings.length < 3) {
                updatedTimings.push(value);
            }
        } else {
            updatedTimings = updatedTimings.filter((timing) => timing !== value);
        }

        setTimings(updatedTimings);
    };

    const handleSaveMedicines = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Payload = {
                ...MedicineData,
                timing: timings.join(','),
                timestamp: new Date().toString(),
                type: "med"
            }
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/medicine`);
            await push(Ref, Payload)
            handleClose()
            setMedicineData({
                medtype: "",
                medicine: "",
                dose: "",
                duration: "",
                anupana: "",
                foodtaken: ""
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;


        setMedicineData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const getMedicineData = async () => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/medicine`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data2 = await snapshot.val()
                    setmedicinedata(Object.entries(data2))
                    console.log(data2)
                }
                catch (err) {
                    console.log(err)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const available_medicines = [
        "Arborium plus",
        "Atasi tailam capsule",
        "Ayukalash",
        "Cardostab tab",
        "Cardojith tab",
        "Chethas Tablets",
        "Jayamangal rasa cap",
        "Marutham tablet",
        "Marutham capsule",
        "Normact tablet",
        "Annapethi chendoora tab",
        "Arborium plus",
        "Ayabringaraja karpam",
        "Aya chendoora tab",
        "Ayurin tablet",
        "Cariaid tablet (plt count)",
        "Chandanadi Q",
        "4blud tablet",
        "Feroliv forte tablet",
        "Haematone syrup",
        "Hb cap capsule",
        "Hb cap syrup",
        "Hemocare syrup",
        "Mandoora vatakam tab",
        "Mandoora vati",
        "Manjishta tablet",
        "Normoplat tablet",
        "Parthamruta syrup",
        "Punarnnavayolepam Choornam",
        "Sariva ghana vati",
        "T.A tab (Triphala annabedi)",
        "Triphalayonlepa choornam",
        "Cinafen tablet",
        "Dblon powder(DM)",
        "Diabecon tablet(DM)",
        "Glycem capsule (DM)",
        "Goranchi tablet",
        "Jambu churnam(DM)",
        "Amritaprasa ghritam",
        "Beejpushti rasa tablet",
        "Count plus granules",
        "Herbodopa tablet",
        "Kameshwara modhakam",
        "Karbakandhi Ennai",
        "Narasimha ghrita saptavarti caps",
        "Nj forte capsule",
        "Oritazh thamarai choornam",
        "Ovoutoline forte",
        "Poonaikali choornam",
        "Poorna chandrodayam tab (skm)",
        "Poweromin",
        "Satavarex granules",
        "Viruthi choornam",
        "Vigorex sf capsule",
        "Kanchanara guggulu tablet",
        "Karela tab (DM)",
        "Madhuheal choornam(DM)",
        "Madhumeha kudineer ch (DM)",
        "Mashumardan choornam(DM)",
        "Madhushakti tab",
        "Meha neerizhivu kiyalam (DM)",
        "Neerazhivu choornam",
        "Nithyakalyani caps (DM)",
        "Niruryadi tablet (DM)",
        "Nishakathakadi kashayam",
        "Siva gulika",
        "Thyrojith tablet",
        "Varunadi ghritam",
        "saptavarti capsule",
        "Aamapachak tonic",
        "Amlapitta mishran",
        "Bhavana kadukkai tab",
        "Chitrakadi gutika",
        "Colicarmin syrup",
        "Dadima syrup",
        "Dasamoolarishtam",
        "Dhanwantaram tablet",
        "Deepani tab",
        "Digestol syrup",
        "Gandarvahastadi taila 21 avarti cap",
        "Gasex tablet",
        "Gastrin syrup",
        "Gid capsule",
        "GHQ tablet",
        "Guluchyadi q tablet",
        "Hinguvachadi tab",
        "Hinguvastaka ch tab",
        "Hingutriguna tailam cap",
        "Inji vatakam",
        "Kaasukatti tablet",
        "Kaaralavana parpam",
        "Kadukkai tab",
        "Kaidaryadi tab",
        "Kunmakudori mezhugu",
        "Khadiramruta syrup",
        "Lasunairandadi Q",
        "Madiphala rasayanam",
        "Manicka ghritam",
        "Omam water",
        "Panchasama choornam",
        "Pep up tablet",
        "Pittashekhar rasa tab",
        "Protekt tablet",
        "Sangu parpam tablet",
        "Shankha vati",
        "Seeraga choornam",
        "Yashtimadhu tablet",
        "Zanacid duo tablet",
        "Ancare tablet",
        "Anuloma ds",
        "Bael tablet",
        "Bavana panjankula tailam caps",
        "Bilva capsule",
        "Bilwadi choornam",
        "Clearout tablet",
        "Chirivilwadi kashayam tab",
        "Depile tab",
        "Dystreptrin tablet",
        "Guggulu pancapala ch tab",
        "Hridyavirechanam leham",
        "Krimighna vati (worms)",
        "Maasikkai Ennai",
        "Mahavilwam",
        "Manibhadra lehyam",
        "Misraka sneham capsule",
        "Mtpex tablet",
        "Nayuruvi nei",
        "Pi apex tablet",
        "Pilex tablet",
        "Pile-x juice plus",
        "Pylapy ointment",
        "Qura tablet by",
        "Triphala guggulu tab",
        "Thayir chundi tab",
        "Ammeno sakthi cap",
        "Ashokadi vati",
        "Ashoka ghana vati",
        "Asoka pattai choornam",
        "Asoka ghritam",
        "Ellumpippalyadi",
        "Evecare capsule",
        "Femitone syrup",
        "Kanchanara guggulu tablet",
        "Kunmakudori mezhugu",
        "Lajjalu choornam",
        "Leucorid tablet",
        "Maasikkai Ennai",
        "Ovapex tablet",
        "Rakthastambhak tablets",
        "Satavarex granules",
        "Stree vyadihari rasa tablet",
        "Vijayadi vati",
        "Apfil tablet",
        "Ayuliv tablet",
        "Caproliv syrup",
        "Draksha avaleham",
        "Drakshadi Q",
        "Heptovit capsules",
        "Kalamegham tab",
        "Kamalai kudineer choornam",
        "Karisalai karpam tab",
        "Katuki ghana vati",
        "Keezhanelli podi",
        "Kynotomine tablet",
        "Livplus capsule",
        "Patolamooladi Q tab",
        "Phalatrikadi Q",
        "Pithorin tablet (gall stones)",
        "Asvagandha Syrup",
        "Asvagandha tablet",
        "Ashwagandha choornam",
        "Boostrum tablet",
        "Immoplus granules",
        "Indukantham ghritham cap(nagarjuna)",
        "Immunoplus granules",
        "Jeevaniya gana Q",
        "Jeevaniya vati tablet",
        "Kalmegh tablet",
        "Narasimha rasayanam",
        "Restora gold tonic",
        "Urico d capsules",
        "Anuloma ds tab",
        "Indravarunikadi choornam",
        "Misraka sneham capsules",
        "Nimbamritadi erandam ds",
        "Laxvit tablet",
        "Vasulax tablet",
        "Gokshuradi sarpirgudam",
        "Jyotismati tailam capsule",
        "Ksheerabala 101 cap",
        "Kapikachu tab",
        "Mahaeladi gutika(DM neuritis)",
        "Mahavilwan tablets",
        "Neurocare capsule",
        "Palsinuron capsule",
        "Poonaikali choornam",
        "Poornachandrodaya tablets",
        "Sahacharadai tailam 21",
        "avarti caps",
        "Sarvamayantaka ghritam",
        "saptavarti caps",
        "Sundheebaladi Choornam",
        "Tantu pashan tab",
        "Zandopa powder",
        "Brahmi tablet",
        "Brahmi pearls caps",
        "Brento forte tablets",
        "Cognitone cap",
        "Gnanavalli tab",
        "Intellect syrup",
        "Intellect tab",
        "Jatamansi tablet",
        "Kalyanaka ghritham",
        "Kattukodi tab",
        "Mahakalyanaka ghritam saptavarti caps",
        "Mandookaparni choornam",
        "Onan sudar tailam (epilepsy)",
        "Panchagavaya ghritam(epilepsy)",
        "Sadamanjil choornam",
        "Vaani ghritam",
        "Cans forte capsule",
        "Carcino",
        "Carcinosakthi ta",
        "Nithyakalyani capsule",
        "Prasad capsule",
        "Ayabringaraja karpam",
        "Kumari tailam",
        "Narasimha ghritam",
        "Narasimha rasayanam",
        "Puluvettu tailam",
        "Tharari choornam",
        "Ulpala choornam",
        "Atasi tailam capsule",
        "Asthiposhak tab",
        "Ayucal tab",
        "Bonton DQ tablets",
        "Bonewiz capsule",
        "Cardocal",
        "Cardocalm tab",
        "Celfree capsule",
        "Energy plus liquid",
        "Gandatailam capsules",
        "Hadjod tablet(bones)",
        "Laksha guggulu (bones)",
        "NVD3",
        "Ranger syrup (anti stress)",
        "Ranger capsule (anti stress)",
        "Eleneer kuzhambu",
        "Jeevantyadi ghritam",
        "Netraamrutham eye drops",
        "Netra thailam",
        "Triphaladi ghritam",
        "Akkara kudineer choornam",
        "Stomatab",
        "Stomist",
        "Kaasukatti tab(gingivitis/dental carries)",
        "Alna fresh tab (sleep)",
        "Alert capsule",
        "Asvagandha Syrup",
        "Asvagandha tablet",
        "Ashwagandha choornam",
        "Cardiosap",
        "Cannapain capsule",
        "Depresol capsule",
        "Hibril capsule",
        "Jatamansi ch",
        "Manasamitra vati",
        "Stressscom capsule",
        "Stressmem caps",
        "Sarpagandha Ghanvati",
        "Tagara tablet",
        "Norbeepee tablet",
        "Normact tablet",
        "Sarpagandha ghanvati",
        "Dasangam gulika tab",
        "Dooshivishari gutika",
        "Eswaramooli tablet",
        "Nanju murivu choornam",
        "Vilwadi gutika tab",
        "Visha vilwadi tab",
        "Aravindasavam",
        "Balasarvangam",
        "Balachaturbhadrika syrup",
        "Colicarmin drops",
        "Gopichandanadi gulika",
        "Urai tablet",
        "Vaani ghrutam",
        "Vasambu tablet",
        "Fifatrol tablet",
        "Gorochanadi gulika",
        "Goranchi tablet",
        "Guduchyadi Q tab",
        "Guduchi tab(vedi)",
        "Jayamangal rasa cap",
        "Kalameghamruta syrup",
        "Kalmegh tablet",
        "Mahadudarshan tablet",
        "Mahaeladi gutika",
        "Pyrid tab",
        "Sudarshanam tablet",
        "Thirithosa sura kiyalam",
        "Narasimha ghritam saptavarti cap",
        "Namira weight gain choornam",
        "Kayakalpa choornam",
        "Sagarlic pearls caps",
        "Vydactin tab",
        "Nilomit tablet",
        "Navaka guggulu",
        "Slimfit juice",
        "Moringa extract",
        "Punarnava extract"
    ]


    const deleteMedicine = (key) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/${id}/medicine/${key}`);
            remove(Ref)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getMedicineData()
    }, [])

   
    return <>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Add Medicine"}
            </DialogTitle>
            <DialogContent>
                <div className='medicineinputtypes' style={{ gridColumn: "1" }}>
                    <TextField label="Medicine type" variant='standard' id="standard-size-small" name="medtype" value={MedicineData?.medtype} onChange={handleChange} />
                    <Autocomplete
                        disablePortal
                        options={available_medicines}
                        value={MedicineData.medicine}
                        onChange={(event, value) => handleChange(event, value)} 
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Intervention/Medicine"
                                name="medicine"
                            />
                        )}
                    />
                    <TextField label="Dose" variant='standard' id="standard-size-small" name="dose" value={MedicineData?.dose} onChange={handleChange} />
                    <TextField label="Anupana" variant='standard' id="standard-size-small" name="anupana" value={MedicineData?.anupana} onChange={handleChange} />
                    <TextField label="Medicine Duration" variant='standard' id="standard-size-small" name="duration" value={MedicineData?.duration} onChange={handleChange} />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Timings</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={timings.includes('Morning')} onChange={handletimingsChange} name="Morning" />}
                                label="Morning"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={timings.includes('Afternoon')} onChange={handletimingsChange} name="Afternoon" />}
                                label="Afternoon"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={timings.includes('Night')} onChange={handletimingsChange} name="Night" />}
                                label="Night"
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="standard-size-small">Take medicine</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="standard-size-small"
                            onChange={handleChange}
                            label="foodtaken"
                            name="foodtaken"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>

                            <MenuItem value={"Before Food"}>Before Food</MenuItem>
                            <MenuItem value={"After Food"}>After Food</MenuItem>

                        </Select>
                    </FormControl>

                </div>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    cancel
                </Button>
                <Button autoFocus onClick={handleSaveMedicines}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Medicines</p>
            <div className='listofreports'>
                {
                    medicinedata.map(([key, e]) => {
                        return <div className='reportcard'>
                            <div>
                                <p style={{ fontSize: "12px", color: "green" }}>{e.timestamp}</p>
                                <Typography variant="body1" component="p" className="mb-0">
                                    <span style={{ fontWeight: 600 }}>Medicine Type </span> {e?.medtype}
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Medicine  <span style={{ fontWeight: 400 }}>{e?.medicine}</span></p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Dose  <span style={{ fontWeight: 400 }}>{e?.dose}</span></p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Anupana  <span style={{ fontWeight: 400 }}>{e?.anupana}</span></p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Medicine Duration  <span style={{ fontWeight: 400 }}>{e?.duration}</span></p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Timing  <span style={{ fontWeight: 400 }}>{e?.timing}</span></p>
                                </Typography>
                                <Typography variant="body1" component="p" className="mb-0" style={{ color: "black" }}>
                                    <p style={{ fontWeight: 600 }}>Medicine Taken  <span style={{ fontWeight: 400 }}>{e?.foodtaken}</span></p>
                                </Typography>
                            </div>
                            <IconButton style={{ alignContent: "flex-end" }}>
                                <DeleteIcon onClick={() => {
                                    deleteMedicine(key)
                                }}></DeleteIcon>
                            </IconButton>
                        </div>
                    })
                }
            </div>
            <div className='addrprtbtn'><Button style={{ color: "white", padding: "8px 3rem 8px 3rem", width: "100%" }} onClick={() => {
                handleClickOpen()
            }}>Add Medicine</Button></div>
        </div>

    </>
}

export default Medicine