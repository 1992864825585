import React from 'react'
import SideBar from './SideBar'
import { useState, useEffect, useRef } from 'react';
import { IconButton, Input, TextField } from '@mui/material'
import { getDatabase, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
import axios from 'axios';
import { db } from './firebase'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'

import CancelIcon from '@mui/icons-material/Cancel';
import { Fade, Slide } from "react-awesome-reveal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchOutlined } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Check from '@mui/icons-material/Check';
import { useContext } from "react";
import { useParams } from 'react-router-dom';
import logo from './ayurailogofull.png'
import { RemoveCircleOutline } from '@mui/icons-material';
import { toast, Zoom } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: 'white',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)'
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <p>1</p>,
        2: <p>2</p>,
        3: <p>3</p>,
        4: <p>4</p>,

    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
      @default false
     
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,

    icon: PropTypes.node,
};

const PrettoSlider = styled(Slider)({
    color: '#blue',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'blue',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const PrettoSlider1 = styled(Slider)({
    color: 'red',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },

    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'red',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const PrettoSlider2 = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});
function CaseSheet() {
    const steps = ['Disease History', 'Personal history', 'Clinical Assessment', 'Diagnosis'];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    let { id } = useParams()
    const [inputs, setInputs] = useState([
        { complaints: '', score: "", quantity: '' },
    ]);
    const [drugs, setdrugs] = useState([
        { brand: '', chemical: '', dose: '', timing: '' },
    ]);

    const handleInputChange = (index, inputValue, field) => {
        setInputs((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index][field] = inputValue;
            return newInputs;
        });
    };
    const handleDrugsInputChange = (index, inputValue, field) => {
        setdrugs((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index][field] = inputValue;
            return newInputs;
        });
    };

    const handleFHInputChange = (index, inputValue, field) => {
        setfamilyHistory((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index][field] = inputValue;
            return newInputs;
        });
    };

    const [PatientDetails, setPatientDetails] = useState(
        {
            Name: "", Age: 0, Height: "", Weight: "",
            Gender: "", Maritalstatus: "", Occupation: "",
            PresentResidence: "", BirthPlace: ""
        }
    )

    const [SleepDetails, setSleepDetails] = useState({
        QualityOFSleep: "",
        Diwaswapna: "No",
        Ratrijagarana: "No"
    })

    const [Bowel, setBowel] = useState({
        BowelMovements: "",
        Koshta: ""
    })

    const [OccupationalHistory, setOccupationalHistory] = useState({
        Physical: "",
        Mental: ""
    })

    const [Micturition, setMicturition] = useState('')

    const [DietType, setDietType] = useState('')
    const [Agni, setAgni] = useState('')
    const [Manas, setManas] = useState('')
    const [Nidra, setNidra] = useState('')
    const [Koshtam, setKostam] = useState('')
    const [Malam, setMalam] = useState('')
    const [Mutram, setMutram] = useState('')
    const [Surgeries, setSurgeries] = useState('')
    const [injuries, setinjuries] = useState('')
    const [eyeSight, setEyesight] = useState('')
    const [menustration, setmenustration] = useState('')
    const [noOfChildren, setChildren] = useState('')
    const [Hysterectomy, setHysterectomy] = useState('')
    const [Clinical, setClinical] = useState('')
    const [Weight, setWeight] = useState('')
    const [height, setHeight] = useState('')
    const [pulseRate, setpulserate] = useState('')
    const [bp, setbp] = useState('')
    const [bt, setbt] = useState('')
    const [Vyayamam, setVyayamam] = useState('')
    const [Vyadgivastha, setVyadgivastha] = useState('')

    const [familyHistory, setfamilyHistory] = useState([
        { history: "" },
    ]);

    const HandlePatientDetails = (key, value) => {
        setPatientDetails(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleGynecological = (key, value) => {
        setGynecological(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleBowel = (key, value) => {
        setBowel(prev => ({
            ...prev,
            [key]: value
        }))
    }






    const HandleOccupationalHistory = (key, value) => {
        setOccupationalHistory(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleSleepDetails = (key, value) => {
        setSleepDetails(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const HandleObstectricalHistory = (key, value) => {
        setObstectricalHistory(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const Navigate = useNavigate()


    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const [complaints, setComplaints] = useState({
        polyuria: { selected: '', duration: '', complaintName: "Prabhuta Mutrata (Polyuria)" },
        turbidUrine: { selected: '', duration: '', complaintName: "Aavil Mutrata (Turbid urine)" },
        polyphagia: { selected: '', duration: '', complaintName: "Kshudhadhikya (Polyphagia)" },
        polydipsia: { selected: '', duration: '', complaintName: "Trishnadhikya (Polydipsia)" },
        throatDryness: { selected: '', duration: '', complaintName: "Gal-Talushosha (Throat dryness)" },
        palmSoleBurning: { selected: '', duration: '', complaintName: "Kara-Padatala Daha (Burning sensation in palm & soles)" },
        legCramps: { selected: '', duration: '', complaintName: "Pindikodwestana (Leg cramps)" },
    })
    const [Othercomplaints, setOtherComplaints] = useState({
        sweetTasteInMouth: { selected: '', duration: '', complaintName: "Asya-Madhurya (Sweet taste in mouth)" },
        teethSensation: { selected: '', duration: '', complaintName: "Danteshu-Malotpatti (Teeth sensation/Pyorrhoea)" },
        sweatingWithSmell: { selected: '', duration: '', complaintName: "Swedaadhikya-Anga-Gandha (Sweating with smell)" },
        blurringVision: { selected: '', duration: '', complaintName: "Netra-Updeha (Blurring vision)" },
        bodyOleosity: { selected: '', duration: '', complaintName: "Deha-Chikkanata (Body Oleosity)" }
    });

    const [diseases, setDiseases] = useState({
        hypertension: { selected: '', duration: '', complaintName: "hypertension" },
        ihdCad: { selected: '', duration: '', complaintName: "ihdCad" },
        bronchial: { selected: '', duration: '', complaintName: "bronchial" },
        tuberculosis: { selected: '', duration: '', complaintName: "tuberculosis" },
        allergy: { selected: '', duration: '', complaintName: "allergy" },
        gastrointestinalDisease: { selected: '', duration: '', complaintName: "gastrointestinalDisease" },
        jaundice: { selected: '', duration: '', complaintName: "jaundice" },
        raOa: { selected: '', duration: '', complaintName: "raOa" },
        skinDisease: { selected: '', duration: '', complaintName: "skinDisease" },
        thyroidDisease: { selected: '', duration: '', complaintName: "thyroidDisease" },
        anaemia: { selected: '', duration: '', complaintName: "anaemia" },
        piles: { selected: '', duration: '', complaintName: "piles" },
        headacheVomiting: { selected: '', duration: '', complaintName: "headacheVomiting" },
        vertigo: { selected: '', duration: '', complaintName: "vertigo" },
        seizures: { selected: '', duration: '', complaintName: "seizures" },
        urinaryCalculi: { selected: '', duration: '', complaintName: "urinaryCalculi" }
    });


    const [diab, setdiab] = useState({
        diabetes: { selected: '', FBS: '', PPBS: "", HbAIC: "", complaintName: "Diabetes mellitus" }
    })
    const SubmitCaseSheet = async () => {
        const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
        const mainResult = {
            AGNI: Agni,
            ALLERGY: diseases?.allergy?.duration,
            ANAEMIA: diseases?.anaemia?.duration,
            APD: "",
            ASSOCIATED: PrakritiDetails?.Assoicated,
            ASTHMA_CASE: "",
            AYURVEDADIAGNOSIS: AyurvedaDiagnois,
            BPCASE: bp,
            CHILDREN: noOfChildren,
            CLINICALEXAMINATION: Clinical,
            DECREASED: Dosham.Decreased,
            DOMINANT: PrakritiDetails?.Dominant,
            DRUGS: drugs.map((e) => {
                return `${e?.brand || 'NA'},${e?.chemical || 'NA'},${e?.dose || 'NA'},${e?.timing || 'NA'}`
            }).join('/'),
            EAOA: diseases?.raOa?.duration,
            EYESIGHT: eyeSight,
            FBS: diab?.diabetes?.FBS,
            HABITS_ALCOHOL: Addictions?.Alcohol?.frequency,
            HABITS_SMOKING: Addictions?.Smoking?.frequency,
            HABITS_TOBACCO: Addictions?.Tobacco?.frequency,
            HBAC: diab?.diabetes?.HbAIC,
            INCREASED: Dosham?.Increased,
            INJURIES: injuries,
            JAUNDICE: diseases?.jaundice?.duration,
            KOSHTAM: Koshtam,
            MALAM: Malam,
            MANAS: Manas,
            MENSTURATION: menustration,
            MODERNDIAGNOSIS: ModernDiagnois,
            MUTRAM: Mutram,
            NIDRA: Nidra,
            OTHERDISEASE: HistoryofPastillness,
            PAST_HISTORY: familyHistory.map((e) => {
                return `${e.history}`
            }).join(','),
            PILES: diseases.piles.duration,
            PPBS: diab?.diabetes?.PPBS,
            PRAKRITIKAPHA: PrakritiKapha,
            PRAKRITIPITTA: PrakritiPitta,
            PRAKRITIVATA: PrakritiVata,
            PRESENTING_COMPLAINTS: inputs.map((e) => {
                return `${e?.complaints || 'NA'}:${e?.score || 'NA'}:${e?.duration || 'NA'}`
            }).join(','),
            PULSERATECASE: pulseRate,
            SEIZURES: diseases?.seizures?.duration,
            SKINDISEASE: diseases?.skinDisease?.duration,
            SURGERIES: Surgeries,
            TB: diseases.tuberculosis.duration,
            TEMPERATURECASE: bt,
            THYROID: diseases.thyroidDisease.duration,
            URINARYCALCULI: diseases.urinaryCalculi.duration,
            VERTIGO: diseases.vertigo.duration,
            VIKRITIKAPHA: DoshamKapha,
            VIKRITIPITTA: DoshamPitta,
            VIKRITIVATA: DoshamVata,
            VYADHIAVASTHA: Vyadgivastha,
            VYAYAMAM: Vyayamam,
            WEIGHTCASE: Weight,
            audiopath: "no",
            doctorname: Details.doctorName,
            patientid: id,
            pulserate: pulseRate,
            timestamp: new Date().toString()
        }
        const db = getDatabase();
        await set(ref(db, `/users/${Details.doctorName}/${id}/casesheet`), mainResult).then((res) =>
            console.log(res)).catch((err) => console.log);
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === 3) {
            setActiveStep(3)
            SubmitCaseSheet()

            setTimeout(() => {
                setActiveStep(0)
            }, 2000)
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [Exercise, setExercise] = useState('')
    const [HistoryofPastillness, setPastillness] = useState('')

    const [ObstectricalHistory, setObstectricalHistory] = useState({
        NoOfDelivery: 0,
        TypeofDelivery: "",
        Hysterectomy: "",
        AnyOther: "none"
    })

    const [PrakritiDetails, setPrakritiDetails] = useState({
        Dominant: '',
        Assoicated: '',
    })

    const [PrakritiVata, setPrakritiVata] = useState(10)
    const [PrakritiPitta, setPrakritiPitta] = useState(10)
    const [PrakritiKapha, setPrakritiKapha] = useState(10)

    const [DoshamVata, setDoshamVata] = useState(10)
    const [DoshamPitta, setDoshamPitta] = useState(10)
    const [DoshamKapha, setDoshamKapha] = useState(10)

    const [Dosham, setDosham] = useState({
        Decreased: '',
        Increased: '',
    })

    const [AyurvedaDiagnois, setAyurvedaDiagnois] = useState('')
    const [ModernDiagnois, setModernDiagnois] = useState('')

    const [foodHabits, setFoodHabits] = useState({
        tea: { selected: '', frequency: '', habitName: 'Tea' },
        coffee: { selected: '', frequency: '', habitName: "Coffee" },
        softDrinks: { selected: '', frequency: '', habitName: "SoftDrinks" },
    });

    const [Gynecological, setGynecological] = useState({
        MenstrualCycle: "",
        MenstrualFlow: "",
        Menopause: ""
    })

    const [Addictions, setAddictions] = useState({
        Tobacco: { selected: '', frequency: '', AddictionName: "Tobacco" },
        Smoking: { selected: '', frequency: '', AddictionName: "Smoking" },
        Alcohol: { selected: '', frequency: '', AddictionName: "Alcohol" },
    });

    const handleRadioChange4 = (habit, value) => {
        setFoodHabits({
            ...foodHabits,
            [habit]: { ...foodHabits[habit], selected: value },
        });
    };

    const handleFrequencyChange4 = (habit, value) => {
        setFoodHabits({
            ...foodHabits,
            [habit]: { ...foodHabits[habit], frequency: value },
        });
    };

    const handleRadioChange5 = (addiction, value) => {
        setAddictions({
            ...Addictions,
            [addiction]: { ...Addictions[addiction], selected: value },
        });
    };

    const handleFrequencyChange5 = (addiction, value) => {
        setAddictions({
            ...Addictions,
            [addiction]: { ...Addictions[addiction], frequency: value },
        });
    };



    const OthercomplaintLabels = {
        sweetTasteInMouth: "Asya-Madhurya (Sweet taste in mouth)",
        teethSensation: "Danteshu-Malotpatti (Teeth sensation/Pyorrhoea)",
        sweatingWithSmell: "Swedaadhikya-Anga-Gandha (Sweating with smell)",
        blurringVision: "Netra-Updeha (Blurring vision)",
        bodyOleosity: "Deha-Chikkanata (Body Oleosity)"
    };

    const complaintsLabels = {
        polyuria: "Prabhuta Mutrata (Polyuria)",
        turbidUrine: "Aavil Mutrata (Turbid urine)",
        polyphagia: "Kshudhadhikya (Polyphagia)",
        polydipsia: "Trishnadhikya (Polydipsia)",
        throatDryness: "Gal-Talushosha (Throat dryness)",
        palmSoleBurning: "Kara-Padatala Daha (Burning sensation in palm & soles)",
        legCramps: "Pindikodwestana (Leg cramps)"
    };

    const handleRadioChange = (symptom, value) => {
        setComplaints({
            ...complaints,
            [symptom]: { ...complaints[symptom], selected: value },
        });
    };

    const handleDurationChange = (complaint, value) => {
        setComplaints({
            ...complaints,
            [complaint]: { ...complaints[complaint], duration: value },
        });
    };

    const handleRadioChange2 = (complaint, value) => {
        setOtherComplaints({
            ...Othercomplaints,
            [complaint]: { ...Othercomplaints[complaint], selected: value },
        });
    };


    const handleDurationChange2 = (symptom, value) => {
        setOtherComplaints({
            ...Othercomplaints,
            [symptom]: { ...Othercomplaints[symptom], duration: value },
        });
    };

    const handleRadioChange3 = (disease, value) => {
        setDiseases({
            ...diseases,
            [disease]: { ...diseases[disease], selected: value },
        });
    };
    const handleRadioChange12 = (disease, value) => {
        setdiab({
            ...diab,
            [disease]: { ...diab[disease], selected: value },
        });
    };

    const handleDurationChange12 = (disease, value, key) => {
        setdiab({
            ...diab,
            [disease]: { ...diab[disease], [key]: value },
        });
    };


    const handleDurationChange3 = (disease, value) => {
        setDiseases({
            ...diseases,
            [disease]: { ...diseases[disease], duration: value },
        });
    };


    const handleRemoveMedicine = (indexToRemove) => {

        const updatedDrugs = [...drugs];

        updatedDrugs.splice(indexToRemove, 1);
        // Update the state with the updated drugs array
        setdrugs(updatedDrugs);
    };



    const handleRemoveFamilyhistory = (indexToRemove) => {

        const updated = [...familyHistory];
        updated.splice(indexToRemove, 1);
        setfamilyHistory(updated);
    };

    const handleRemovecomplaints = (indexToRemove) => {

        const updated = [...inputs];
        updated.splice(indexToRemove, 1);
        setInputs(updated);
    };

    const HandlePrakriti = (key, value, checked) => {
        let selectedDoshas = PrakritiDetails[key] ? PrakritiDetails[key].split(',').filter(Boolean) : [];
        if (checked) {
            if (selectedDoshas.length < 3) {
                selectedDoshas.push(value);
            } else {

                console.log('Maximum selection limit reached');
            }
        } else {
            selectedDoshas = selectedDoshas.filter(dosha => dosha !== value);
        }
        setPrakritiDetails(prev => ({
            ...prev,
            [key]: selectedDoshas.join(',')
        }));
    };

    const HandleDosham = (key, value, checked) => {
        let selectedDoshas = Dosham[key] ? Dosham[key].split(',').filter(Boolean) : [];
        if (checked) {
            if (selectedDoshas.length < 3) {
                selectedDoshas.push(value);
            } else {

                console.log('Maximum selection limit reached');
            }
        } else {
            selectedDoshas = selectedDoshas.filter(dosha => dosha !== value);
        }
        setDosham(prev => ({
            ...prev,
            [key]: selectedDoshas.join(',')
        }))
    }


    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };


    const handleAddField = () => {
        setInputs([...inputs, { complaints: '', score: "", duration: '' }]);
    };

    const handleFHField = () => {
        setfamilyHistory([...familyHistory, { history: "" }])
    };

    const handleDrugsField = () => {
        setdrugs([...drugs, { brand: '', chemical: '', dose: '', timing: '' }])
    };

    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 pt-5 rounded-lg dark:border-gray-700 mt-14">
                <div className='prescription_container'>
                    <div className='Forms mt-15'>
                        <h1>Case Sheet</h1>
                        <div className='content-box'>
                            <Box sx={{ width: '100%' }}>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption">Optional</Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps} >
                                                <StepLabel StepIconComponent={ColorlibStepIcon} >
                                                    <p id='step'>{label}</p></StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                    <>
                                        <div className='container'>

                                            <div className='client-basic-information'>
                                                <div className='last-section'>
                                                    <Fade><img className='last-section-img' src='ayurailogofull.png'>

                                                    </img>
                                                    </Fade>
                                                    <Slide><p id='last-section-p'>Thank you for choosing Ayur.AI</p></Slide>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        {activeStep === 0 ?
                                            <div className='container'>
                                                <Fade>
                                                    <div className='client-basic-information'>

                                                        <div className='Medication'>
                                                            <div className='diagnois'>
                                                                <p>Complaints</p>
                                                                <div>
                                                                    <>{inputs.map((input, index) => (
                                                                        <div className='medicine' key={index}>

                                                                            <TextField
                                                                                className='medicine-input'
                                                                                label="Enter Complaints"
                                                                                id="standard-size-small"
                                                                                value={input.complaints}
                                                                                onChange={(event) => handleInputChange(index, event.target.value, "complaints")}
                                                                                variant="standard"
                                                                            />
                                                                            <TextField
                                                                                label="Score"
                                                                                id="standard-size-small"
                                                                                className='medicine-input'
                                                                                value={input.score}
                                                                                onChange={(event) => handleInputChange(index, event.target.value, "score")}
                                                                                variant="standard"
                                                                            />
                                                                            <TextField
                                                                                label="Duration"
                                                                                className='medicine-input'
                                                                                id="standard-size-small"
                                                                                value={input.duration}
                                                                                onChange={(event) => handleInputChange(index, event.target.value, "duration")}
                                                                                variant="standard"
                                                                            />
                                                                            <IconButton onClick={() => handleRemovecomplaints(index)} style={index > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
                                                                                <CancelIcon style={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </div>))}
                                                                        <div className='add-more' onClick={handleAddField}>
                                                                            Add Complaints <AddCircleOutlineIcon />
                                                                        </div>
                                                                    </>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>History of past illness</p>
                                                                    <textarea row={6} onChange={(e) => {
                                                                        setPastillness(e.target.value)
                                                                    }}></textarea>
                                                                </div>
                                                                {/* <div className='Current-medication'>
                                                                    {Object.entries(complaints).map(([symptom, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{complaintsLabels[symptom]}</p>
                                                                                <div>
                                                                                    <input type="radio" name={symptom} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange(symptom, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={symptom} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange(symptom, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange(symptom, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div> */}
                                                            </div>
                                                            {/* <div className='diagnois'>
                                                                <p>Other Associated Complaints</p>

                                                                <div className='Current-medication'>
                                                                    {Object.entries(Othercomplaints).map(([symptom, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{OthercomplaintLabels[symptom]}</p>
                                                                                <div>
                                                                                    <input type="radio" name={symptom} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange2(symptom, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={symptom} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange2(symptom, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange2(symptom, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div> */}
                                                            <div className='diagnois'>
                                                                <p>Diseases</p>
                                                                <div className='Current-medication'>
                                                                    {Object.entries(diab).map(([disease, { selected, PPBS, FBS, HbAIC, complaintName }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{complaintName}</p>
                                                                                <div>
                                                                                    <input type="radio" name={complaintName} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange12(disease, e.target.value)} />
                                                                                    <label for="diap">Yes</label><br />
                                                                                    <input type="radio" name={complaintName} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange12(disease, e.target.value)} />
                                                                                    <label for="diab2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "row", gap: '12px' }}>
                                                                                    <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <label htmlFor="fbsInput" style={{ display: 'inline-block', marginRight: '5px' }}>
                                                                                                FBS
                                                                                            </label>
                                                                                            <TextField
                                                                                                variant='standard'
                                                                                                id="standard-size-small"
                                                                                                value={FBS}
                                                                                                onChange={(e) => { handleDurationChange12(disease, e.target.value, "FBS") }}
                                                                                                placeholder=""
                                                                                                style={{ display: 'inline-block', width: '80px' }}
                                                                                            />
                                                                                            <span style={{ display: 'inline-block', marginLeft: '5px' }}>mg/dl</span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <label htmlFor="fbsInput" style={{ display: 'inline-block', marginRight: '5px' }}>
                                                                                                PPBS
                                                                                            </label>
                                                                                            <TextField
                                                                                                variant='standard'
                                                                                                id="standard-size-small"
                                                                                                value={PPBS}
                                                                                                onChange={(e) => { handleDurationChange12(disease, e.target.value, "PPBS") }}
                                                                                                placeholder=""
                                                                                                style={{ display: 'inline-block', width: '80px' }} // Adjust width as needed
                                                                                            />
                                                                                            <span style={{ display: 'inline-block', marginLeft: '5px' }}>mg/dl</span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <label htmlFor="fbsInput" style={{ display: 'inline-block', marginRight: '5px' }}>
                                                                                                HbA1c
                                                                                            </label>
                                                                                            <TextField
                                                                                                variant='standard'
                                                                                                id="standard-size-small"
                                                                                                value={HbAIC}
                                                                                                onChange={(e) => { handleDurationChange12(disease, e.target.value, "HbAIC") }}
                                                                                                placeholder=""
                                                                                                style={{ display: 'inline-block', width: '80px' }}
                                                                                            />
                                                                                            <span style={{ display: 'inline-block', marginLeft: '5px', visibility: "hidden" }}>mg/dl</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p></p>
                                                                <div className='Current-medication'>
                                                                    {Object.entries(diseases).map(([disease, { selected, duration }]) => (
                                                                        <div className='disorders-card'>
                                                                            <div>
                                                                                <p>{disease.replace(/([A-Z])/g, ' $1').trim()}</p>
                                                                                <div>
                                                                                    <input type="radio" name={disease} value="Yes" checked={selected === 'Yes'} onChange={(e) => handleRadioChange3(disease, e.target.value)} />
                                                                                    <label for="html">Yes</label><br />
                                                                                    <input type="radio" name={disease} value="No" checked={selected === 'No'} onChange={(e) => handleRadioChange3(disease, e.target.value)} />
                                                                                    <label for="QualityofSleep2">No</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {selected === 'Yes' && (
                                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '12px' }}>
                                                                                    <p style={{ fontWeight: 400 }}>
                                                                                        Enter Duration
                                                                                    </p>
                                                                                    <input
                                                                                        type="text"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        placeholder="Duration"
                                                                                        value={duration}
                                                                                        onChange={(e) => handleDurationChange3(disease, e.target.value)}
                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                            <div className='diagnois'>
                                                                <p>Others</p>
                                                                <textarea row={6} onChange={(e) => {
                                                                    setPastillness(e.target.value)
                                                                }}></textarea>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </Fade>
                                            </div> : activeStep === 1 ? <div className='container'>
                                                <Fade>
                                                    <div className='client-basic-information'>
                                                        <div className='Medication'>
                                                            <div className='Current-medication'>
                                                                <p style={{ marginTop: "1rem" }}>EyeSight</p>
                                                                <TextField id="standard-size-small" onChange={(e) => {
                                                                    setEyesight(e.target.value)
                                                                }} />
                                                                <div className='diagnois'>
                                                                    <p>Injuries</p>
                                                                    <TextField id="standard-size-small" onChange={(e) => {
                                                                        setinjuries(e.target.value)
                                                                    }} />
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Surgeries</p>
                                                                    <TextField id="standard-size-small" onChange={(e) => {
                                                                        setSurgeries(e.target.value)
                                                                    }} />
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Menstruation / Obstectrical</p>
                                                                    <TextField id="standard-size-small" onChange={(e) => {
                                                                        setmenustration(e.target.value)
                                                                    }} />
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Addictions</p>
                                                                    <div className='Current-medication'>

                                                                        {Object.entries(Addictions).map(([habit, { selected, frequency }]) => (
                                                                            <div className='disorders-card'>
                                                                                <div>
                                                                                    <p>{habit.charAt(0).toUpperCase() + habit.slice(1)} </p>
                                                                                    <div>
                                                                                        <input type="radio" value="Yes" checked={selected === 'Yes'}
                                                                                            onChange={(e) => handleRadioChange5(habit, e.target.value)} />
                                                                                        <label for="html">Yes</label><br />
                                                                                        <input type="radio" value="No" checked={selected === 'No'}
                                                                                            onChange={(e) => handleRadioChange5(habit, e.target.value)} />
                                                                                        <label>No</label><br></br>
                                                                                    </div>

                                                                                </div>
                                                                                {selected === 'Yes' && (
                                                                                    <TextField
                                                                                        style={{ margin: "1rem" }}
                                                                                        variant="standard"
                                                                                        value={frequency}
                                                                                        label="Enter Years"
                                                                                        id="standard-size-small"
                                                                                        onChange={(e) => handleFrequencyChange5(habit, e.target.value)}
                                                                                    >
                                                                                    </TextField>
                                                                                )}

                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>

                                                                            <div>
                                                                                <p style={{ fontSize: "18px" }}>Hysterectomy</p>
                                                                                <div >
                                                                                    <input type="radio" id="Exercise1" name="Exercise" value="Total" onChange={(e) => { setHysterectomy(e.target.value) }} />
                                                                                    <label for="html">Total</label><br />
                                                                                    <input type="radio" id="Exercise1" name="Exercise" value="Partial" onChange={(e) => { setHysterectomy(e.target.value) }} />
                                                                                    <label for="QualityofSleep2" >Partial</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Children</p>
                                                                    <div className='Current-medication'>
                                                                        <div className='disorders-card'>

                                                                            <TextField
                                                                                className='medicine-input'
                                                                                label="Enter no of childrens"
                                                                                id="standard-size-small"
                                                                                variant='standard'
                                                                                onChange={(e) => {
                                                                                    setChildren(e.target.value)
                                                                                }}
                                                                            />

                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <div className='diagnois'>
                                                                    <p>Family history of Diseases </p>
                                                                    <>{familyHistory.map((input, index) => (
                                                                        <div className='medicine' key={index}>

                                                                            <TextField
                                                                                className='medicine-input'
                                                                                label="Enter Discription"
                                                                                id="standard-size-small"
                                                                                value={input.history}
                                                                                onChange={(event) => handleFHInputChange(index, event.target.value, "history")}

                                                                            />
                                                                            <IconButton onClick={() => handleRemoveFamilyhistory(index)} style={index > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
                                                                                <CancelIcon style={{ color: "red" }} />
                                                                            </IconButton>
                                                                        </div>))}
                                                                        <div className='add-more' onClick={handleFHField}>
                                                                            Add <AddCircleOutlineIcon />
                                                                        </div>
                                                                    </>
                                                                </div>
                                                                <div className='diagnois'>
                                                                    <p>Current Drugs</p>                                                                </div>
                                                                <>{drugs.map((input, index) => (
                                                                    <div className='medicine' key={index}>

                                                                        <TextField
                                                                            className='medicine-input'
                                                                            label="Brand"
                                                                            id="standard-size-small"
                                                                            value={input.brand}
                                                                            onChange={(event) => handleDrugsInputChange(index, event.target.value, "brand")}

                                                                        />
                                                                        <TextField
                                                                            className='medicine-input'
                                                                            label="Chemical"
                                                                            id="standard-size-small"
                                                                            value={input.chemical}
                                                                            onChange={(event) => handleDrugsInputChange(index, event.target.value, "chemical")}

                                                                        />
                                                                        <TextField
                                                                            className='medicine-input'
                                                                            label="Dose"
                                                                            id="standard-size-small"
                                                                            value={input.dose}
                                                                            onChange={(event) => handleDrugsInputChange(index, event.target.value, "dose")}

                                                                        />
                                                                        <TextField
                                                                            className='medicine-input'
                                                                            label="Timings"
                                                                            id="standard-size-small"
                                                                            value={input.timing}
                                                                            onChange={(event) => handleDrugsInputChange(index, event.target.value, "timing")}

                                                                        />
                                                                        <IconButton onClick={() => handleRemoveMedicine(index)} style={index > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
                                                                            <CancelIcon style={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </div>))}
                                                                    <div className='add-more' onClick={handleDrugsField}>
                                                                        Add <AddCircleOutlineIcon />
                                                                    </div>
                                                                </>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Clinical Examination</p>
                                                                <textarea onChange={(e) => {
                                                                    setClinical(e.target.value)
                                                                }}></textarea>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Height</p>
                                                                <TextField onChange={(e) => {
                                                                    setHeight(e.target.value)
                                                                }}></TextField>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Weight</p>
                                                                <TextField onChange={(e) => {
                                                                    setWeight(e.target.value)
                                                                }}></TextField>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Pulse Rate</p>
                                                                <TextField onChange={(e) => {
                                                                    setpulserate(e.target.value)
                                                                }}></TextField>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Blood Pressure</p>
                                                                <TextField onChange={(e) => {
                                                                    setbp(e.target.value)
                                                                }}></TextField>
                                                            </div>
                                                            <div className='diagnois'>
                                                                <p>Body Temperature</p>
                                                                <TextField onChange={(e) => {
                                                                    setbt(e.target.value)
                                                                }}></TextField>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Fade>
                                            </div> : activeStep === 2 ?
                                                <div className='container'>
                                                    <Fade>
                                                        <div className='client-basic-information'>
                                                            <div className='Medication'>
                                                                <div className='Current-medication'>
                                                                    <div className='diagnois'>
                                                                        <p>Prakriti</p>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Dominant</p>
                                                                                {/* <div >
                                                                                    <input type="radio" id="Dominant1" name="Dominan" value="Vata" onChange={(e) => {
                                                                                        HandlePrakriti('Dominant', e.target.value)
                                                                                    }} />
                                                                                    <label for="html">Vata </label><br />
                                                                                    <input type="radio" id="Dominant2" name="Dominan" value="Pitta" onChange={(e) => {
                                                                                        HandlePrakriti('Dominant', e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Pitta</label><br></br>
                                                                                    <input type="radio" id="Dominant3" name="Dominan" value="Kapha" onChange={(e) => {
                                                                                        HandlePrakriti('Dominant', e.target.value)
                                                                                    }} />
                                                                                    <label for="QualityofSleep2">Kapha</label><br></br>

                                                                                </div> */}
                                                                                <div>
                                                                                    <input type="checkbox" id="Dominant1" name="Dominant" value="Vata"

                                                                                        onChange={(e) => {
                                                                                            HandlePrakriti('Dominant', e.target.value, e.target.checked)
                                                                                        }} disabled={PrakritiDetails['Assoicated'] && PrakritiDetails['Assoicated'].includes('Vata')} />
                                                                                    <label htmlFor="Dominant1">Vata</label><br />
                                                                                    <input type="checkbox" id="Dominant2" name="Dominant" value="Pitta" onChange={(e) => {
                                                                                        HandlePrakriti('Dominant', e.target.value, e.target.checked)
                                                                                    }} disabled={PrakritiDetails['Assoicated'] && PrakritiDetails['Assoicated'].includes('Pitta')} />
                                                                                    <label htmlFor="Dominant2">Pitta</label><br></br>
                                                                                    <input type="checkbox" id="Dominant3" name="Dominant" value="Kapha" onChange={(e) => {
                                                                                        HandlePrakriti('Dominant', e.target.value, e.target.checked)
                                                                                    }} disabled={PrakritiDetails['Assoicated'] && PrakritiDetails['Assoicated'].includes('Kapha')} />
                                                                                    <label htmlFor="Dominant3">Kapha</label><br></br>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Assoicated</p>
                                                                                <div>

                                                                                    <input type="checkbox" id="Associated1" name="Associated" value="Vata" onChange={(e) => {
                                                                                        HandlePrakriti('Assoicated', e.target.value, e.target.checked)
                                                                                    }} disabled={PrakritiDetails['Dominant'] && PrakritiDetails['Dominant'].includes('Vata')} />
                                                                                    <label htmlFor="Associated1">Vata</label><br />
                                                                                    <input type="checkbox" id="Associated2" name="Associated" value="Pitta" onChange={(e) => {
                                                                                        HandlePrakriti('Assoicated', e.target.value, e.target.checked)
                                                                                    }} disabled={PrakritiDetails['Dominant'] && PrakritiDetails['Dominant'].includes('Pitta')} />
                                                                                    <label htmlFor="Associated2">Pitta</label><br></br>
                                                                                    <input type="checkbox" id="Associated3" name="Associated" value="Kapha" onChange={(e) => {
                                                                                        HandlePrakriti('Assoicated', e.target.value, e.target.checked)
                                                                                    }} disabled={PrakritiDetails['Dominant'] && PrakritiDetails['Dominant'].includes('Kapha')} />
                                                                                    <label htmlFor="Associated3">Kapha</label><br></br>
                                                                                </div>
                                                                            </div>
                                                                            {/* 
                                                                            <div className='disorders-card'>
                                                                                <p>Vata</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={PrakritiVata}
                                                                                    onChange={(e, values) => {
                                                                                        setPrakritiVata(values)
                                                                                    }}

                                                                                />

                                                                            </div> */}
                                                                            {/* <div className='disorders-card'>
                                                                                <p>Pitta</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider1
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    values={PrakritiPitta}
                                                                                    onChange={(e, values) => {
                                                                                        setPrakritiPitta(values)
                                                                                    }}

                                                                                />

                                                                            </div> */}
                                                                            {/* <div className='disorders-card'>
                                                                                <p>Kapha</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider2
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={PrakritiKapha}
                                                                                    onChange={(e, values) => {
                                                                                        setPrakritiKapha(values)
                                                                                    }}

                                                                                />

                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='diagnois'>
                                                                        <p>Dosham</p>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Decreased</p>
                                                                                <div >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="Associated1"
                                                                                        name="Associated"
                                                                                        value="Vata"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Vata')}
                                                                                    />
                                                                                    <label htmlFor="Associated1">Vata</label><br />

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="Associated2"
                                                                                        name="Associated"
                                                                                        value="Pitta"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Pitta')}
                                                                                    />
                                                                                    <label htmlFor="Associated2">Pitta</label><br />

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="Associated3"
                                                                                        name="Associated"
                                                                                        value="Kapha"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Decreased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Increased'] && Dosham['Increased'].includes('Kapha')}
                                                                                    />
                                                                                    <label htmlFor="Associated3">Kapha</label><br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='disorders-card'>
                                                                            <div style={{ width: "25rem" }}>
                                                                                <p>Increased</p>
                                                                                <div >
                                                                                <input
                                                                                        type="checkbox"
                                                                                        id="Associated1"
                                                                                        name="Associated"
                                                                                        value="Vata"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Vata')}
                                                                                    />
                                                                                    <label htmlFor="Associated1">Vata</label><br />

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="Associated2"
                                                                                        name="Associated"
                                                                                        value="Pitta"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Pitta')}
                                                                                    />
                                                                                    <label htmlFor="Associated2">Pitta</label><br />

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="Associated3"
                                                                                        name="Associated"
                                                                                        value="Kapha"
                                                                                        onChange={(e) => {
                                                                                            HandleDosham('Increased', e.target.value, e.target.checked);
                                                                                        }}
                                                                                        disabled={Dosham['Decreased'] && Dosham['Decreased'].includes('Kapha')}
                                                                                    />
                                                                                    <label htmlFor="Associated3">Kapha</label><br />
                                                                                </div>
                                                                            </div>


                                                                            {/* <div className='disorders-card'>
                                                                                <p>Vata</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamVata}
                                                                                    onChange={(e, values) => {
                                                                                        setDoshamVata(values)
                                                                                    }}

                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Pitta</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider1
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamPitta}
                                                                                    onChange={(e, value) => {
                                                                                        setDoshamPitta(value)
                                                                                    }}

                                                                                />

                                                                            </div>
                                                                            <div className='disorders-card'>
                                                                                <p>Kapha</p>
                                                                                &nbsp;&nbsp;
                                                                                <PrettoSlider2
                                                                                    valueLabelDisplay="auto"
                                                                                    aria-label="pretto slider"
                                                                                    value={DoshamKapha}
                                                                                    onChange={(e, values) => {
                                                                                        setDoshamKapha(values)
                                                                                    }}

                                                                                />
                                                                            </div> */}
                                                                            <div className='Current-medication'>

                                                                                <div className='client-inputs' style={{ flexDirection: "column" }}>


                                                                                    <div>
                                                                                        <p>
                                                                                            Agni
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', gap: "1rem" }}>
                                                                                            <input type="radio" id="Agni1" name="Agni" value="Poor" onClick={(e) => {
                                                                                                setAgni(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Poor</label>
                                                                                            <input type="radio" id="Agni2" name="Agni" value="Excess" onClick={(e) => {
                                                                                                setAgni(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Excess</label>
                                                                                            <input type="radio" id="Agni3" name="Agni" value='Decreased' onClick={(e) => {
                                                                                                setAgni(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Decreased</label>
                                                                                            <input type="radio" id="Agni4" name="Agni" value="NA" onClick={(e) => {
                                                                                                setAgni(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p>
                                                                                            Manas
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', gap: "1rem", width: "20rem" }}>
                                                                                            <input type="radio" id="Manas1" name="Manas" value="Normal" onClick={(e) => {
                                                                                                setManas(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Normal</label>
                                                                                            <input type="radio" id="Manas2" name="Manas" value="Excess" onClick={(e) => {
                                                                                                setManas(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Excess</label>
                                                                                            <input type="radio" id="Manas3" name="Manas" value='Decreased' onClick={(e) => {
                                                                                                setManas(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Decreased</label>
                                                                                            <input type="radio" id="Manas4" name="Manas" value="NA" onClick={(e) => {
                                                                                                setManas(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p>
                                                                                            Nidra / Duration
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', gap: "1rem", width: "20rem" }}>
                                                                                            <input type="radio" id="Nidra1" name="Nidra" value="Normal" onClick={(e) => {
                                                                                                setNidra(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Normal</label>
                                                                                            <input type="radio" id="Nidra2" name="Nidra" value="Excess" onClick={(e) => {
                                                                                                setNidra(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Excess</label>
                                                                                            <input type="radio" id="Nidra3" name="Nidra" value='Decreased' onClick={(e) => {
                                                                                                setNidra(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Decreased</label>
                                                                                            <input type="radio" id="Nidra4" name="Nidra" value="NA" onClick={(e) => {
                                                                                                setNidra(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p>
                                                                                            Koshtam
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', gap: "1rem", width: "20rem" }}>
                                                                                            <input type="radio" id="Koshtam1" name="Koshtam" value="Kurra" onClick={(e) => {
                                                                                                setKostam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Kurra</label>
                                                                                            <input type="radio" id="Koshtam2" name="Koshtam" value="Mrdu" onClick={(e) => {
                                                                                                setKostam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Mrdu</label>
                                                                                            <input type="radio" id="Koshtam3" name="Koshtam" value='Madhva' onClick={(e) => {
                                                                                                setKostam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Madhva</label>
                                                                                            <input type="radio" id="Koshtam4" name="Koshtam" value="NA" onClick={(e) => {
                                                                                                setKostam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            Malam
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', width: "20rem", gap: "1rem" }}>
                                                                                            <input type="radio" id="Malam1" name="Malam" value="Hard" onClick={(e) => {
                                                                                                setMalam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Hard</label>
                                                                                            <input type="radio" id="Malam2" name="Malam" value="Loose" onClick={(e) => {
                                                                                                setMalam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Loose</label>
                                                                                            <input type="radio" id="Malam3" name="Malam" value='Normal' onClick={(e) => {
                                                                                                setMalam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Normal</label>
                                                                                            <input type="radio" id="Malam4" name="Malam" value="NA" onClick={(e) => {
                                                                                                setMalam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            Mutram
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', width: "20rem", gap: "1rem" }}>
                                                                                            <input type="radio" id="Mutram1" name="Mutram" value="Scanty" onClick={(e) => {
                                                                                                setMutram(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Scanty</label>
                                                                                            <input type="radio" id="Mutram2" name="Mutram" value="Excess" onClick={(e) => {
                                                                                                setMutram(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Excess</label>
                                                                                            <input type="radio" id="Mutram3" name="Mutram" value='Normal' onClick={(e) => {
                                                                                                setMutram(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Normal</label>
                                                                                            <input type="radio" id="Mutram4" name="Mutram" value="NA" onClick={(e) => {
                                                                                                setMutram(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            Vyayamam
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', width: "20rem", gap: "1rem" }}>
                                                                                            <input type="radio" id="Vyayamam1" name="Vyayamam" value="NIL" onClick={(e) => {
                                                                                                setVyayamam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NIL</label>
                                                                                            <input type="radio" id="Vyayamam2" name="Vyayamam" value="Excess" onClick={(e) => {
                                                                                                setVyayamam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Moderate</label>
                                                                                            <input type="radio" id="Vyayamam3" name="Vyayamam" value='Normal' onClick={(e) => {
                                                                                                setVyayamam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">Excess</label>
                                                                                            <input type="radio" id="Vyayamam" name="Vyayamam" value="NA" onClick={(e) => {
                                                                                                setVyayamam(e.target.value)
                                                                                            }} />
                                                                                            <label for="gender1">NA</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            Vyadgivastha
                                                                                        </p>
                                                                                        <div style={{ display: 'flex', width: "20rem", gap: "1rem" }}>
                                                                                            <TextField onChange={(e) => {
                                                                                                setVyadgivastha(e?.target?.value)
                                                                                            }}></TextField>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Fade>
                                                </div> : activeStep === 3 ? <div className='container'>
                                                    <Fade>
                                                        <div className='client-basic-information'>
                                                            <div className='Medication'>
                                                                <div className='Current-medication'>
                                                                    <p>Ayurveda diagnosis</p>
                                                                    <textarea height={'10rem'} onChange={(e) => {
                                                                        setAyurvedaDiagnois(e.target.value)
                                                                    }}></textarea>
                                                                    <p>Modern diagnosis</p>
                                                                    <textarea height={'10rem'} onChange={(e) => {
                                                                        setModernDiagnois(e.target.value)
                                                                    }}></textarea>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div> : <></>}
                                        <div className='action-buttons'>
                                            <div className='action-buttons-box'>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {isStepOptional(activeStep) && (
                                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                        Skip
                                                    </Button>
                                                )}

                                                <Button onClick={handleNext} style={{ backgroundImage: 'linear-gradient(to top, #00c6fb 0%, #005bea 100%)', color: 'white' }}>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CaseSheet
