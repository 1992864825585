import logo from './logo.svg';
import './App.css';
import Login from './Compontents/Login';
import Dashboard from './Compontents/Dashboard';
import Active_users from './Compontents/Active_user';
import DoctorsAppointment from './Compontents/DoctorsAppointment';
import Patient from './Compontents/Patient';
import { ToastContainer } from 'react-toastify';
import VideoPage from './Compontents/video';
import VoiceCall from './Compontents/VoiceCall';
import { VideoCall } from './Compontents/VideoCall';
import FollowUpAssesment from './Compontents/FollowUPAssesment';
import Prescription from './Compontents/Prescription';
import CaseStudy from './Compontents/CaseStudy';
import CaseSheet from './Compontents/CaseSheet';
import UserCaseStudy from './Compontents/UserCaseStudy';
import Chat from './Compontents/Chat';
import Reports from './Compontents/Reports';
import ViewReports from './Compontents/ViewReports'
import Medicine from './Compontents/Medicine';
import LabReports from './Compontents/LabReports';
import BookAppointment from './Compontents/BookAppointment';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
function App() {
  return <>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
       
        <Route path='/Home' element={<Dashboard />} />
        <Route path='/' element={<Login />} />
        <Route path='/Activeusers' element={<Active_users />} />
        <Route path='/Prescription/:id' element={<Prescription />} />
        <Route path='/ViewReports/:id/:Reportid' element={<ViewReports />} />
        <Route path='/Reports/:id/' element={<Reports />} />
        <Route path='/Patient/:id' element={<Patient />} />
        <Route path='/CaseSheet/:id' element={<CaseSheet />} />
        <Route path='/CaseStudy' element={<CaseStudy />} />
        <Route path='/DoctorAppointment' element={<DoctorsAppointment />} />
        <Route path='/UserCaseStudy/:id' element={<UserCaseStudy></UserCaseStudy>} />
        <Route path='/VideoCall/:channel' element={<VideoCall></VideoCall>}/>
        <Route path='/Medicine/:id' element={<Medicine/>}/>
        <Route path='/VoiceCall/:channel' element={<VoiceCall/>}/>
        <Route path='/BookAppointment/:id' element={<BookAppointment/>}/>
        <Route path='/FollowUpAssesment/:id' element={<FollowUpAssesment/>}/>
        <Route path='/LabReports/:id' element={<LabReports/>}/>
        <Route path='/Chat/:id/:pid' element={<Chat />} />

      </Routes>
    </BrowserRouter>
  </>
}

export default App;
